import { useRef } from "react";

const useMenuState = (isActive) => {
  const currentClass = useRef("closed");

  const isInactive = "closed";

  const states = {
    old: {
      isActive: "old",
      isInactive,
    },
    new: {
      isActive: "old",
      isInactive,
    },
    closed: {
      isActive: "new",
      isInactive,
    },
  };

  const currentState = states[currentClass.current];
  currentClass.current =
    isActive !== null ? currentState.isActive : currentState.isInactive;

  return currentClass.current;
};

export default useMenuState;
