import React from "react";
import SubNavigation from "../SubNavigation";
import AccountSubNavigation from "./AccountSubNavigation";
import AccountIcon from "./AccountIcon";
import Login from "theme/pages/Login/Login.js";
import Register from "theme/pages/Register/Register.js";
import HeroM from "theme/components/atoms/Typography/Heading/HeroM";
import { defineMessages, injectIntl } from "react-intl";
import Button from "theme/components/atoms/Button";
import Panel from "theme/components/molecules/Panel";
import { compose, withHandlers, withState } from "recompose";

const message = defineMessages({
  overtitle: {
    id: "layouts.Header.Navigation.AccountNavigation.overtitle",
    defaultMessage: "Se connecter",
  },
  title: {
    id: "layouts.Header.Navigation.AccountNavigation.title",
    defaultMessage: "Enchanté",
  },
  textLogin: {
    id: "layouts.Header.Navigation.AccountNavigation.textLogin",
    defaultMessage:
      "Creez un compte ou connectez-vous pour profiter de nos services sur-mesure",
  },
  textList1: {
    id: "layouts.Header.Navigation.AccountNavigation.textList1",
    defaultMessage: "Payer plus rapidement",
  },
  textList2: {
    id: "layouts.Header.Navigation.AccountNavigation.textList2",
    defaultMessage: "Enregistrer plusieurs adresses de livraison",
  },
  textList3: {
    id: "layouts.Header.Navigation.AccountNavigation.textList3",
    defaultMessage: "Voir et suivre les commandes",
  },
  btnCreateAccount: {
    id: "layouts.Header.Navigation.AccountNavigation.btnCreateAccount",
    defaultMessage: "Créer un compte",
  },
  btnConnection: {
    id: "layouts.Header.Navigation.AccountNavigation.btnConnection",
    defaultMessage: "Se connecter",
  },
  panelTitleLogin: {
    id: "layouts.Header.Navigation.AccountNavigation.panelTitleLogin",
    defaultMessage: "Se connecter",
  },
  panelTitleRegister: {
    id: "layouts.Header.Navigation.AccountNavigation.panelTitleRegister",
    defaultMessage: "S'inscrire",
  },
  accountMobile: {
    id: "layouts.Header.Navigation.AccountNavigation.accountMobile",
    defaultMessage: "My account",
  },
});

const AccountNavigation = ({
  intl,
  loading,
  user,
  selectMenu,
  renderSubNav,
  openPanel,
  onSubNavClosed,
  setOpenPanel,
  openLogin,
  setOpenState,
  openMenu,
}) => {
  return user?.id ? (
    <>
      <AccountIcon
        loading={loading}
        user={user}
        onUserMenu={() => selectMenu("account")}
        text={intl.formatMessage(message.accountMobile)}
        openMenu={openMenu ?? undefined}
      />
      {renderSubNav("account")(
        <SubNavigation appearance="aside" onClose={() => selectMenu(null)}>
          <AccountSubNavigation user={user} />
        </SubNavigation>
      )}
    </>
  ) : (
    <>
      <AccountIcon
        loading={loading}
        user={user}
        onUserMenu={() => selectMenu("login")}
        text={intl.formatMessage(message.accountMobile)}
        openMenu={openMenu}
      />
      {renderSubNav("login")(
        <>
          <SubNavigation onClose={onSubNavClosed}>
            <div className="account-subnavigation ctn-fluid">
              <div className="row">
                <div className="account-subnavigation__overtitle my-4 mt-md-5 mb-md-1 xxs12">
                  {intl.formatMessage(message.overtitle)}
                </div>
                <div className="offset-md4 md8 xxs12 account-subnavigation__title">
                  <HeroM as="div">{intl.formatMessage(message.title)}</HeroM>
                  <div className="row mt-10 mt-md-7">
                    <div className="xxs12 sm6">
                      <div className="account-subnavigation__desc xxs-ctn left">
                        {intl.formatMessage(message.textLogin)}
                      </div>
                    </div>
                    <div className="xxs12 sm6">
                      <ul className="formated-list">
                        <li>{intl.formatMessage(message.textList1)}</li>
                        <li>{intl.formatMessage(message.textList2)}</li>
                        <li>{intl.formatMessage(message.textList3)}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="out-of-ctn border-extralight-top hidden-xxs show-md desktop-only mt-5 out-of-ctn"></div>
              <div className="account-navigation__footer mt-5 mt-md-0">
                <div className="py-4 offset-md4 md8">
                  <div className="row">
                    <div className="xxs12 sm6">
                      <div className="btn-ctn btn-fw">
                        <Button
                          appearance="reverse"
                          onClick={() => {
                            setOpenState(false);
                            setOpenPanel(true);
                          }}
                        >
                          {intl.formatMessage(message.btnCreateAccount)}
                        </Button>
                      </div>
                    </div>
                    <div className="xxs12 sm6 mt-2 mt-sm-0">
                      <div className="btn-ctn btn-fw">
                        <Button
                          appearance="reverse"
                          onClick={() => {
                            setOpenState(true);
                            setOpenPanel(true);
                          }}
                        >
                          {intl.formatMessage(message.btnConnection)}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SubNavigation>
          <Panel
            title={
              openLogin
                ? intl.formatMessage(message.panelTitleLogin)
                : intl.formatMessage(message.panelTitleRegister)
            }
            open={openPanel}
            onClose={() => setOpenPanel(false)}
            footer=""
          >
            {openLogin ? (
              <Login fromPanel="true" setOpenState={setOpenState} />
            ) : (
              <Register fromPanel="true" setOpenState={setOpenState} />
            )}
          </Panel>
        </>
      )}
    </>
  );
};

export default compose(
  injectIntl,
  withState("openPanel", "setOpenPanel", false),
  withState("openLogin", "setOpenState", true),
  withHandlers({
    onSubNavClosed:
      ({ selectMenu }) =>
      () => {
        selectMenu(null);
      },
  })
)(AccountNavigation);
