import React from "react";
import Link from "theme/components/atoms/Typography/Link/Link";
import Wysiwyg from "theme/modules/WysiwygV2";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  title: {
    id: "modules.Prismic.Blocks.SearchSuggestions.title",
    defaultMessage: "Suggested",
  },
});

const SearchSuggestions = ({ linkList }) => {
  const intl = useIntl();

  return (
    <div className="search-suggestions row start-xxs mb-5">
      <div className="search-suggestions__title xxs nogrow p2 pr-6">
        {intl.formatMessage(messages.title)}
      </div>
      <div className="search-suggestions__links xxs nogrow">
        {linkList.map((link, i) => (
          <div
            className="search-suggestions__links__link h4 my-0"
            key={`suggestion${i}`}
          >
            <Link to={link.link}>
              <Wysiwyg content={link.linkLabel} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchSuggestions;
