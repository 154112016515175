import React, { useEffect } from "react";
import { withRouter, Route } from "react-router";
import Header from "./Header";
import Footer from "./Footer";
import GenericLayout from "./GenericLayout";
import Modal from "react-modal";

const Layout = (props) => {
  const header = <Header />;
  const footer = (
    <Footer
      categories={props.categories}
      accountDetails={props.accountDetails}
      contact={props.contact}
      footerLinks={props.footerLinks}
    />
  );

  const children = <div className="mdl-layout__content">{props.children}</div>;

  useEffect(() => {
    if (
      props.location.pathname === "/gift-guide" ||
      props.location.pathname === "/magic-holiday"
    ) {
      const element = document.querySelector("body");
      element.style.backgroundColor = "black";
      element.style.color = "white";
    } else {
      const element = document.querySelector("body");
      element.style.backgroundColor = "";
      element.style.color = "";
    }
  }, [props.location]);

  if (process.env.SERVER) {
    return (
      <GenericLayout location={props.location} header={header} footer={footer}>
        {children}
      </GenericLayout>
    );
  }

  return (
    <GenericLayout location={props.location} header={header} footer={footer}>
      <Route
        location={props.isModal ? props.previousLocation : props.location}
        render={() => children}
      />
      {props.isModal ? (
        <Modal
          onRequestClose={() => props.history.push(props.previousLocation)}
          isOpen
          className="modal__content"
          overlayClassName="modal__overlay"
          contentLabel="Modal"
        >
          {props.children}
        </Modal>
      ) : null}
    </GenericLayout>
  );
};

export default withRouter(Layout);
