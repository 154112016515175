import React from "react";
import { CartInfosHeader } from "theme/modules/Cart/CartItem/CartItemInfos";
import { FormattedMessage } from "react-intl";

const CartHeader = () => (
  <div className="cart-header">
    <div className="cart-header__item">
      <FormattedMessage
        id="modules.Cart.CartContent.CartHeader.products"
        defaultMessage="Products"
      />
    </div>
    <div className="cart-header__item">
      <CartInfosHeader />
    </div>
    <div className="cart-header__item"></div>
  </div>
);
export default CartHeader;
