import React from "react";
import classNames from "classnames";
import ProductsResults from "../ProductsResults";
import Stack from "theme/components/atoms/Layout/Stack";
import SearchBarResultsError from "./SearchBarResultsEmptyError";
import Spinner from "theme/components/atoms/Spinner";

const hasResults = ({ products, categories, pages }) =>
  products.length > 0 || categories.length > 0 || pages.length > 0;

const SearchBarResults = (props) => {
  const isEmpty = !props.results || !hasResults(props.results);

  if (props.loading && isEmpty) {
    return <Spinner />;
  }
  if (props.error) {
    return <SearchBarResultsError />;
  }

  const hasProducts = props.results.products.length > 0;
  const hasCategories = props.results.categories.length > 0;
  const hasPages = props.results.pages.length > 0;

  return (
    <div
      className={classNames("searchbar-results px-4", {
        "searchbar-results--two-columns":
          hasProducts && (hasCategories || hasPages),
      })}
    >
      <Stack size="2">
        <div className="searchbar-results__results">
          {hasProducts && (
            <div key="products" className="searchbar-results__element">
              <ProductsResults
                results={props.results.products}
                selected={props.selected}
                onSelect={props.onSelect}
              />
            </div>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default SearchBarResults;
