// antadis : add same classes as cartItem + chge jsx
import React from "react";
import { compose } from "recompose";
import { defineMessages, useIntl } from "react-intl";
import { H4 } from "theme/components/atoms/Typography/Heading";
import EnhanceCartItemQuantityForm from "../CartItemQuantityForm/EnhanceCartItemQuantityForm";
import EnhanceRemoveItemCart from "../CartItemRemoveForm/EnhanceRemoveItemCart";
import UpdateCartItemQtyMutation from "theme/modules/Cart/CartItem/CartItemQuantityForm/UpdateCartItemQtyMutation.gql";
import RemoveCartItemMutation from "theme/modules/Cart/CartItem/CartItemRemoveForm/RemoveCartItemMutation.gql";
import Image from "theme/components/atoms/Image";
import Price from "theme/components/atoms/Typography/Price";
import CartItemStatus from "../CartItemStatus";
import CartItemQuantityForm from "../CartItemQuantityForm";
import TrackingLink from "theme/components/atoms/Typography/Link/TrackingLink";
import Button from "theme/components/atoms/Button";
import { StackElement } from "theme/components/atoms/Layout/Stack";
import SelectedOptions from "theme/modules/ProductView/SelectedOptions/SelectedOptions";

const messages = defineMessages({
  removeItem: {
    id: "modules.Cart.MiniCart.MiniCartContent.MiniCartItem.removeItem",
    defaultMessage: "Remove",
  },
  optionLabel: {
    id: "modules.Cart.MiniCart.MiniCartContent.MiniCartItem.optionLabel",
    defaultMessage: "{optionName}: {optionValue}",
  },
});
// stock qty in constant form MiniCartItem responsive
const MiniCartItemInfo = ({
  item,
  onRefreshQuantity,
  refreshQuantityStatus,
  removeItemStatus,
}) => {
  return (
    <div className="cart-infos">
      <div className="mini-cart-item__quantity cart-infos__item cart-infos__item--quantity">
        <CartItemQuantityForm
          quantity={item.qty}
          item_id={item.item_id}
          disabled={removeItemStatus.commandPending}
          onRefreshQuantity={onRefreshQuantity}
          refreshQuantityPending={refreshQuantityStatus.commandPending}
        />
      </div>
      <div className="mini-cart-item__price cart-infos__item text-right">
        <Price price={item.priceInfo.rowTotalInclTax} />
      </div>
    </div>
  );
};

const MiniCartItem = ({
  item,
  onRefreshQuantity,
  refreshQuantityStatus,
  removeItemStatus,
  removeItem,
}) => {
  console.log(item);
  const intl = useIntl();
  return (
    <div className="mini-cart-item cart-item dark">
      <div className="cart-item__cell dark cart-item__cell--description">
        <div className="mini-cart-item__details cart-item-details">
          <div className="mini-cart-item__image cart-item-details__image">
            {item.product?.imageUrl ? (
              <Image
                src={item.product.imageUrl}
                alt={item.name}
                format="small"
              />
            ) : null}
          </div>
          <div className="mini-cart-item__content cart-item-details__content">
            <div className="mini-cart-item__name cart-item-details__name">
              <H4>
                {item.product ? (
                  <TrackingLink
                    to={item.product.path}
                    eventName="Product Clicked"
                    eventPayload={{
                      sku: item.sku,
                      name: item.product.name,
                    }}
                  >
                    {item.product.name}
                  </TrackingLink>
                ) : (
                  item.product.name
                )}
              </H4>
              <div className="mini-cart-item__name p2">
                {item.product.subtitle}
              </div>
              <SelectedOptions
                options={item.options}
                bundleOptions={item.bundleOptions}
              />
            </div>
            <CartItemStatus
              refreshQuantityStatus={refreshQuantityStatus}
              removeItemStatus={removeItemStatus}
            />
            <div className="cart-item-details__options flex col-sm">
              <StackElement appearance="mobile-only">
                <MiniCartItemInfo
                  item={item}
                  onRefreshQuantity={onRefreshQuantity}
                  refreshQuantityStatus={refreshQuantityStatus}
                  removeItemStatus={removeItemStatus}
                />
              </StackElement>

              <div className="mini-cart-item__remove">
                <Button
                  onClick={removeItem}
                  appearance="link"
                  state={
                    removeItemStatus.commandPending
                      ? "pending"
                      : refreshQuantityStatus.commandPending
                      ? "disabled"
                      : undefined
                  }
                  onDisableClick={() => {}}
                >
                  {intl.formatMessage(messages.removeItem)}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-item__cell dark">
        <StackElement appearance="desktop-only">
          <MiniCartItemInfo
            item={item}
            onRefreshQuantity={onRefreshQuantity}
            refreshQuantityStatus={refreshQuantityStatus}
            removeItemStatus={removeItemStatus}
          />
        </StackElement>
      </div>
    </div>
  );
};

export default compose(
  EnhanceRemoveItemCart({ RemoveCartItemMutation }),
  EnhanceCartItemQuantityForm({ UpdateCartItemQtyMutation })
)(MiniCartItem);
