export const GermanIsocodeMapping = [
  {
    iso_code: "AF",
    country_name: "Afghanistan",
  },
  {
    iso_code: "EG",
    country_name: "Ägypten",
  },
  {
    iso_code: "AX",
    country_name: "Ålandinseln",
  },
  {
    iso_code: "AL",
    country_name: "Albanien",
  },
  {
    iso_code: "DZ",
    country_name: "Algerien",
  },
  {
    iso_code: "AS",
    country_name: "Amerikanisch-Samoa",
  },
  {
    iso_code: "VI",
    country_name: "Amerikanische Jungferninseln",
  },
  {
    iso_code: "UM",
    country_name: "Amerikanische Überseeinseln",
  },
  {
    iso_code: "AD",
    country_name: "Andorra",
  },
  {
    iso_code: "AO",
    country_name: "Angola",
  },
  {
    iso_code: "AI",
    country_name: "Anguilla",
  },
  {
    iso_code: "AQ",
    country_name: "Antarktis",
  },
  {
    iso_code: "AG",
    country_name: "Antigua und Barbuda",
  },
  {
    iso_code: "GQ",
    country_name: "Äquatorialguinea",
  },
  {
    iso_code: "AR",
    country_name: "Argentinien",
  },
  {
    iso_code: "AM",
    country_name: "Armenien",
  },
  {
    iso_code: "AW",
    country_name: "Aruba",
  },
  {
    iso_code: "AZ",
    country_name: "Aserbaidschan",
  },
  {
    iso_code: "ET",
    country_name: "Äthiopien",
  },
  {
    iso_code: "AU",
    country_name: "Australien",
  },
  {
    iso_code: "BS",
    country_name: "Bahamas",
  },
  {
    iso_code: "BH",
    country_name: "Bahrain",
  },
  {
    iso_code: "BD",
    country_name: "Bangladesch",
  },
  {
    iso_code: "BB",
    country_name: "Barbados",
  },
  {
    iso_code: "BY",
    country_name: "Belarus",
  },
  {
    iso_code: "BE",
    country_name: "Belgien",
  },
  {
    iso_code: "BZ",
    country_name: "Belize",
  },
  {
    iso_code: "BJ",
    country_name: "Benin",
  },
  {
    iso_code: "BM",
    country_name: "Bermuda",
  },
  {
    iso_code: "BT",
    country_name: "Bhutan",
  },
  {
    iso_code: "BO",
    country_name: "Bolivien",
  },
  {
    iso_code: "BQ",
    country_name: "Bonaire, Sint Eustatius und Saba",
  },
  {
    iso_code: "BA",
    country_name: "Bosnien und Herzegowina",
  },
  {
    iso_code: "BW",
    country_name: "Botsuana",
  },
  {
    iso_code: "BV",
    country_name: "Bouvetinsel",
  },
  {
    iso_code: "BR",
    country_name: "Brasilien",
  },
  {
    iso_code: "VG",
    country_name: "Britische Jungferninseln",
  },
  {
    iso_code: "IO",
    country_name: "Britisches Territorium im Indischen Ozean",
  },
  {
    iso_code: "BN",
    country_name: "Brunei Darussalam",
  },
  {
    iso_code: "BG",
    country_name: "Bulgarien",
  },
  {
    iso_code: "BF",
    country_name: "Burkina Faso",
  },
  {
    iso_code: "BI",
    country_name: "Burundi",
  },
  {
    iso_code: "CV",
    country_name: "Cabo Verde",
  },
  {
    iso_code: "CL",
    country_name: "Chile",
  },
  {
    iso_code: "CN",
    country_name: "China",
  },
  {
    iso_code: "CK",
    country_name: "Cookinseln",
  },
  {
    iso_code: "CR",
    country_name: "Costa Rica",
  },
  {
    iso_code: "CI",
    country_name: "Côte d’Ivoire",
  },
  {
    iso_code: "CW",
    country_name: "Curaçao",
  },
  {
    iso_code: "DK",
    country_name: "Dänemark",
  },
  {
    iso_code: "DE",
    country_name: "Deutschland",
  },
  {
    iso_code: "DM",
    country_name: "Dominica",
  },
  {
    iso_code: "DO",
    country_name: "Dominikanische Republik",
  },
  {
    iso_code: "DJ",
    country_name: "Dschibuti",
  },
  {
    iso_code: "EC",
    country_name: "Ecuador",
  },
  {
    iso_code: "SV",
    country_name: "El Salvador",
  },
  {
    iso_code: "ER",
    country_name: "Eritrea",
  },
  {
    iso_code: "EE",
    country_name: "Estland",
  },
  {
    iso_code: "SZ",
    country_name: "Eswatini",
  },
  {
    iso_code: "FK",
    country_name: "Falklandinseln",
  },
  {
    iso_code: "FO",
    country_name: "Färöer",
  },
  {
    iso_code: "FJ",
    country_name: "Fidschi",
  },
  {
    iso_code: "FI",
    country_name: "Finnland",
  },
  {
    iso_code: "FR",
    country_name: "Frankreich",
  },
  {
    iso_code: "GF",
    country_name: "Französisch-Guayana",
  },
  {
    iso_code: "PF",
    country_name: "Französisch-Polynesien",
  },
  {
    iso_code: "TF",
    country_name: "Französische Süd- und Antarktisgebiete",
  },
  {
    iso_code: "GA",
    country_name: "Gabun",
  },
  {
    iso_code: "GM",
    country_name: "Gambia",
  },
  {
    iso_code: "GE",
    country_name: "Georgien",
  },
  {
    iso_code: "GH",
    country_name: "Ghana",
  },
  {
    iso_code: "GI",
    country_name: "Gibraltar",
  },
  {
    iso_code: "GD",
    country_name: "Grenada",
  },
  {
    iso_code: "GR",
    country_name: "Griechenland",
  },
  {
    iso_code: "GL",
    country_name: "Grönland",
  },
  {
    iso_code: "GP",
    country_name: "Guadeloupe",
  },
  {
    iso_code: "GU",
    country_name: "Guam",
  },
  {
    iso_code: "GT",
    country_name: "Guatemala",
  },
  {
    iso_code: "GG",
    country_name: "Guernsey",
  },
  {
    iso_code: "GN",
    country_name: "Guinea",
  },
  {
    iso_code: "GW",
    country_name: "Guinea-Bissau",
  },
  {
    iso_code: "GY",
    country_name: "Guyana",
  },
  {
    iso_code: "HT",
    country_name: "Haiti",
  },
  {
    iso_code: "HM",
    country_name: "Heard und McDonaldinseln",
  },
  {
    iso_code: "HN",
    country_name: "Honduras",
  },
  {
    iso_code: "IN",
    country_name: "Indien",
  },
  {
    iso_code: "ID",
    country_name: "Indonesien",
  },
  {
    iso_code: "IQ",
    country_name: "Irak",
  },
  {
    iso_code: "IR",
    country_name: "Iran",
  },
  {
    iso_code: "IE",
    country_name: "Irland",
  },
  {
    iso_code: "IS",
    country_name: "Island",
  },
  {
    iso_code: "IM",
    country_name: "Isle of Man",
  },
  {
    iso_code: "IL",
    country_name: "Israel",
  },
  {
    iso_code: "IT",
    country_name: "Italien",
  },
  {
    iso_code: "JM",
    country_name: "Jamaika",
  },
  {
    iso_code: "JP",
    country_name: "Japan",
  },
  {
    iso_code: "YE",
    country_name: "Jemen",
  },
  {
    iso_code: "JE",
    country_name: "Jersey",
  },
  {
    iso_code: "JO",
    country_name: "Jordanien",
  },
  {
    iso_code: "KY",
    country_name: "Kaimaninseln",
  },
  {
    iso_code: "KH",
    country_name: "Kambodscha",
  },
  {
    iso_code: "CM",
    country_name: "Kamerun",
  },
  {
    iso_code: "CA",
    country_name: "Kanada",
  },
  {
    iso_code: "KZ",
    country_name: "Kasachstan",
  },
  {
    iso_code: "QA",
    country_name: "Katar",
  },
  {
    iso_code: "KE",
    country_name: "Kenia",
  },
  {
    iso_code: "KG",
    country_name: "Kirgisistan",
  },
  {
    iso_code: "KI",
    country_name: "Kiribati",
  },
  {
    iso_code: "CC",
    country_name: "Kokosinseln",
  },
  {
    iso_code: "CO",
    country_name: "Kolumbien",
  },
  {
    iso_code: "KM",
    country_name: "Komoren",
  },
  {
    iso_code: "CG",
    country_name: "Kongo-Brazzaville",
  },
  {
    iso_code: "CD",
    country_name: "Kongo-Kinshasa",
  },
  {
    iso_code: "HR",
    country_name: "Kroatien",
  },
  {
    iso_code: "CU",
    country_name: "Kuba",
  },
  {
    iso_code: "KW",
    country_name: "Kuwait",
  },
  {
    iso_code: "LA",
    country_name: "Laos",
  },
  {
    iso_code: "LS",
    country_name: "Lesotho",
  },
  {
    iso_code: "LV",
    country_name: "Lettland",
  },
  {
    iso_code: "LB",
    country_name: "Libanon",
  },
  {
    iso_code: "LR",
    country_name: "Liberia",
  },
  {
    iso_code: "LY",
    country_name: "Libyen",
  },
  {
    iso_code: "LI",
    country_name: "Liechtenstein",
  },
  {
    iso_code: "LT",
    country_name: "Litauen",
  },
  {
    iso_code: "LU",
    country_name: "Luxemburg",
  },
  {
    iso_code: "MG",
    country_name: "Madagaskar",
  },
  {
    iso_code: "MW",
    country_name: "Malawi",
  },
  {
    iso_code: "MY",
    country_name: "Malaysia",
  },
  {
    iso_code: "MV",
    country_name: "Malediven",
  },
  {
    iso_code: "ML",
    country_name: "Mali",
  },
  {
    iso_code: "MT",
    country_name: "Malta",
  },
  {
    iso_code: "MA",
    country_name: "Marokko",
  },
  {
    iso_code: "MH",
    country_name: "Marshallinseln",
  },
  {
    iso_code: "MQ",
    country_name: "Martinique",
  },
  {
    iso_code: "MR",
    country_name: "Mauretanien",
  },
  {
    iso_code: "MU",
    country_name: "Mauritius",
  },
  {
    iso_code: "YT",
    country_name: "Mayotte",
  },
  {
    iso_code: "MX",
    country_name: "Mexiko",
  },
  {
    iso_code: "FM",
    country_name: "Mikronesien",
  },
  {
    iso_code: "MC",
    country_name: "Monaco",
  },
  {
    iso_code: "MN",
    country_name: "Mongolei",
  },
  {
    iso_code: "ME",
    country_name: "Montenegro",
  },
  {
    iso_code: "MS",
    country_name: "Montserrat",
  },
  {
    iso_code: "MZ",
    country_name: "Mosambik",
  },
  {
    iso_code: "MM",
    country_name: "Myanmar",
  },
  {
    iso_code: "NA",
    country_name: "Namibia",
  },
  {
    iso_code: "NR",
    country_name: "Nauru",
  },
  {
    iso_code: "NP",
    country_name: "Nepal",
  },
  {
    iso_code: "NC",
    country_name: "Neukaledonien",
  },
  {
    iso_code: "NZ",
    country_name: "Neuseeland",
  },
  {
    iso_code: "NI",
    country_name: "Nicaragua",
  },
  {
    iso_code: "NL",
    country_name: "Niederlande",
  },
  {
    iso_code: "NE",
    country_name: "Niger",
  },
  {
    iso_code: "NG",
    country_name: "Nigeria",
  },
  {
    iso_code: "NU",
    country_name: "Niue",
  },
  {
    iso_code: "KP",
    country_name: "Nordkorea",
  },
  {
    iso_code: "MP",
    country_name: "Nördliche Marianen",
  },
  {
    iso_code: "MK",
    country_name: "Nordmazedonien",
  },
  {
    iso_code: "NF",
    country_name: "Norfolkinsel",
  },
  {
    iso_code: "NO",
    country_name: "Norwegen",
  },
  {
    iso_code: "OM",
    country_name: "Oman",
  },
  {
    iso_code: "AT",
    country_name: "Österreich",
  },
  {
    iso_code: "PK",
    country_name: "Pakistan",
  },
  {
    iso_code: "PS",
    country_name: "Palästinensische Autonomiegebiete",
  },
  {
    iso_code: "PW",
    country_name: "Palau",
  },
  {
    iso_code: "PA",
    country_name: "Panama",
  },
  {
    iso_code: "PG",
    country_name: "Papua-Neuguinea",
  },
  {
    iso_code: "PY",
    country_name: "Paraguay",
  },
  {
    iso_code: "PE",
    country_name: "Peru",
  },
  {
    iso_code: "PH",
    country_name: "Philippinen",
  },
  {
    iso_code: "PN",
    country_name: "Pitcairninseln",
  },
  {
    iso_code: "PL",
    country_name: "Polen",
  },
  {
    iso_code: "PT",
    country_name: "Portugal",
  },
  {
    iso_code: "PR",
    country_name: "Puerto Rico",
  },
  {
    iso_code: "MD",
    country_name: "Republik Moldau",
  },
  {
    iso_code: "RE",
    country_name: "Réunion",
  },
  {
    iso_code: "RW",
    country_name: "Ruanda",
  },
  {
    iso_code: "RO",
    country_name: "Rumänien",
  },
  {
    iso_code: "RU",
    country_name: "Russland",
  },
  {
    iso_code: "SB",
    country_name: "Salomonen",
  },
  {
    iso_code: "ZM",
    country_name: "Sambia",
  },
  {
    iso_code: "WS",
    country_name: "Samoa",
  },
  {
    iso_code: "SM",
    country_name: "San Marino",
  },
  {
    iso_code: "ST",
    country_name: "São Tomé und Príncipe",
  },
  {
    iso_code: "SA",
    country_name: "Saudi-Arabien",
  },
  {
    iso_code: "SE",
    country_name: "Schweden",
  },
  {
    iso_code: "CH",
    country_name: "Schweiz",
  },
  {
    iso_code: "SN",
    country_name: "Senegal",
  },
  {
    iso_code: "RS",
    country_name: "Serbien",
  },
  {
    iso_code: "SC",
    country_name: "Seychellen",
  },
  {
    iso_code: "SL",
    country_name: "Sierra Leone",
  },
  {
    iso_code: "ZW",
    country_name: "Simbabwe",
  },
  {
    iso_code: "SG",
    country_name: "Singapur",
  },
  {
    iso_code: "SX",
    country_name: "Sint Maarten",
  },
  {
    iso_code: "SK",
    country_name: "Slowakei",
  },
  {
    iso_code: "SI",
    country_name: "Slowenien",
  },
  {
    iso_code: "SO",
    country_name: "Somalia",
  },
  {
    iso_code: "HK",
    country_name: "Sonderverwaltungsregion Hongkong",
  },
  {
    iso_code: "MO",
    country_name: "Sonderverwaltungsregion Macau",
  },
  {
    iso_code: "ES",
    country_name: "Spanien",
  },
  {
    iso_code: "SJ",
    country_name: "Spitzbergen und Jan Mayen",
  },
  {
    iso_code: "LK",
    country_name: "Sri Lanka",
  },
  {
    iso_code: "BL",
    country_name: "St. Barthélemy",
  },
  {
    iso_code: "SH",
    country_name: "St. Helena",
  },
  {
    iso_code: "KN",
    country_name: "St. Kitts und Nevis",
  },
  {
    iso_code: "LC",
    country_name: "St. Lucia",
  },
  {
    iso_code: "MF",
    country_name: "St. Martin",
  },
  {
    iso_code: "PM",
    country_name: "St. Pierre und Miquelon",
  },
  {
    iso_code: "VC",
    country_name: "St. Vincent und die Grenadinen",
  },
  {
    iso_code: "ZA",
    country_name: "Südafrika",
  },
  {
    iso_code: "SD",
    country_name: "Sudan",
  },
  {
    iso_code: "GS",
    country_name: "Südgeorgien und die Südlichen Sandwichinseln",
  },
  {
    iso_code: "KR",
    country_name: "Südkorea",
  },
  {
    iso_code: "SS",
    country_name: "Südsudan",
  },
  {
    iso_code: "SR",
    country_name: "Suriname",
  },
  {
    iso_code: "SY",
    country_name: "Syrien",
  },
  {
    iso_code: "TJ",
    country_name: "Tadschikistan",
  },
  {
    iso_code: "TW",
    country_name: "Taiwan",
  },
  {
    iso_code: "TZ",
    country_name: "Tansania",
  },
  {
    iso_code: "TH",
    country_name: "Thailand",
  },
  {
    iso_code: "TL",
    country_name: "Timor-Leste",
  },
  {
    iso_code: "TG",
    country_name: "Togo",
  },
  {
    iso_code: "TK",
    country_name: "Tokelau",
  },
  {
    iso_code: "TO",
    country_name: "Tonga",
  },
  {
    iso_code: "TT",
    country_name: "Trinidad und Tobago",
  },
  {
    iso_code: "TD",
    country_name: "Tschad",
  },
  {
    iso_code: "CZ",
    country_name: "Tschechien",
  },
  {
    iso_code: "TN",
    country_name: "Tunesien",
  },
  {
    iso_code: "TR",
    country_name: "Türkei",
  },
  {
    iso_code: "TM",
    country_name: "Turkmenistan",
  },
  {
    iso_code: "TC",
    country_name: "Turks- und Caicosinseln",
  },
  {
    iso_code: "TV",
    country_name: "Tuvalu",
  },
  {
    iso_code: "UG",
    country_name: "Uganda",
  },
  {
    iso_code: "UA",
    country_name: "Ukraine",
  },
  {
    iso_code: "HU",
    country_name: "Ungarn",
  },
  {
    iso_code: "UY",
    country_name: "Uruguay",
  },
  {
    iso_code: "UZ",
    country_name: "Usbekistan",
  },
  {
    iso_code: "VU",
    country_name: "Vanuatu",
  },
  {
    iso_code: "VA",
    country_name: "Vatikanstadt",
  },
  {
    iso_code: "VE",
    country_name: "Venezuela",
  },
  {
    iso_code: "AE",
    country_name: "Vereinigte Arabische Emirate",
  },
  {
    iso_code: "US",
    country_name: "Vereinigte Staaten",
  },
  {
    iso_code: "GB",
    country_name: "Vereinigtes Königreich",
  },
  {
    iso_code: "VN",
    country_name: "Vietnam",
  },
  {
    iso_code: "WF",
    country_name: "Wallis und Futuna",
  },
  {
    iso_code: "CX",
    country_name: "Weihnachtsinsel",
  },
  {
    iso_code: "EH",
    country_name: "Westsahara",
  },
  {
    iso_code: "CF",
    country_name: "Zentralafrikanische Republik",
  },
  {
    iso_code: "CY",
    country_name: "Zypern",
  },
];
