import React from "react";
import PropTypes from "prop-types";
import Icon from "theme/components/atoms/Icon";
import Body from "theme/components/atoms/Typography/Body";
import classnames from "classnames";
import IconWithNotification from "../IconWithNotification";

const LabelledIcon = ({
  icon,
  children,
  inverted,
  iconAppearance,
  appearance,
  notification,
}) => {
  const classes = classnames("labelled-icon", {
    [`labelled-icon--${appearance}`]: appearance,
    [`labelled-icon--icon-${iconAppearance}`]: iconAppearance,
  });
  return (
    <span className={classes}>
      <span className="labelled-icon__content">
        {inverted && <Body>{children}</Body>}
        <span className="labelled-icon__icon p2">
          {notification !== undefined ? (
            <div className="p2">
              <IconWithNotification
                notification={notification}
                title=""
                icon={icon}
                size="small"
                appearance={iconAppearance}
              />
            </div>
          ) : (
            <div className="p2">
              <Icon
                title=""
                icon={icon}
                size="small"
                appearance={iconAppearance}
              />
            </div>
          )}
        </span>
        {!inverted && <div className="p2">{children}</div>}
      </span>
    </span>
  );
};

LabelledIcon.propTypes = {
  icon: Icon.propTypes.icon,
  children: Body.propTypes.children,
  inverted: PropTypes.bool,
  appearance: PropTypes.oneOf(["default", "vertical", "block"]),
  notification: PropTypes.number,
};

export default LabelledIcon;
