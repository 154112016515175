import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Link from "theme/components/atoms/Typography/Link";
import FormActions from "theme/components/molecules/Form/FormActions";

const messages = defineMessages({
  seeCartAction: {
    id: "modules.Cart.MiniCart.MiniCartContent.seeCart",
    defaultMessage: "View my cart",
  },
  checkoutAction: {
    id: "modules.Cart.MiniCart.MiniCartContent.checkout",
    defaultMessage: "Proceed to checkout",
  },
});

const MiniCartActions = () => {
  const intl = useIntl();

  return (
    <FormActions appearance="full">
      <Link buttonAppearance="primary" to="/checkout">
        {intl.formatMessage(messages.checkoutAction)}
      </Link>
    </FormActions>
  );
};

export default MiniCartActions;
