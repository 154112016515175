import React, {useContext} from "react";
import MiniCartItem from "theme/modules/Cart/CartItem/MiniCartItem";
import Link from "theme/components/atoms/Typography/Link";
import { defineMessages, useIntl } from "react-intl";
import MiniCartContext from './MiniCartContext';
import Wysiwyg from "theme/modules/WysiwygV2";

const messages = defineMessages({
  miniCartEmpty: {
    id: "modules.Cart.MiniCart.MiniCartContent.empty",
    defaultMessage: "You don't have any product in your cart yet.",
  },
  viewMore: {
    id: "modules.Cart.MiniCart.MiniCartContent.viewMore",
    defaultMessage: "See my {qty} products",
  },
});

const MiniCartItemList = ({ cart }) => {
  const prismicEmptyContent = useContext(MiniCartContext).miniCartEmptyContent;
  const intl = useIntl();
  return (
    <>
      {cart.items.length > 0 ? (
        <div className="cart-content__list">
          {cart.items.slice(0, 4).map((item) => (
            <MiniCartItem key={item.item_id} item={item} />
          ))}
          {cart.items.length > 4 && (
            <div key="viewAll" className="center">
              <Link to="/cart">
                {intl.formatMessage(messages.viewMore, {
                  qty: cart.items_qty,
                })}
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="h3">
          <Wysiwyg content={prismicEmptyContent.text} />
        </div>
      )}
    </>
  );
};

export default MiniCartItemList;
