import React from "react";
import { compose } from "recompose";
import { useLocation } from "react-router";
import ProductItem from "theme/modules/ProductView/ProductItem";
import ProductItemSkeleton from "theme/modules/ProductView/ProductItem/ProductItemSkeleton";
import EnhanceLayerProducts from "./EnhanceLayerProducts";
import Image from "theme/components/atoms/Image";
import useMediaQueryProps from "theme/components/helpers/useMediaQueryProps";

const PrismicPromote = ({ product, promote, promoteData, isDesktop }) => {
  const { image, image_mobile } = promoteData;
  return (
    <div className="prismic-content">
      {image && (
        <Image
          src={isDesktop ? image.url : image_mobile.url}
          alt={image.alt ?? product.name}
          cover
          format="original"
        />
      )}
    </div>
  );
};

const LayerProducts = ({
  loading,
  layer,
  hasFilters,
  EmptyComponent,
  breadcrumb,
  imageSizes,
  prioritizeTop = 0,
  getLayerProductsElementClassName,
  isMarketingLine,
  promote,
}) => {
  const location = useLocation();
  const { isDesktop } = useMediaQueryProps();
  let stillHavePrismicToDisplay = true;

  if (loading) {
    return (
      <div className="layer-products row start-xxs mt-4">
        {new Array(5).fill(null).map((_, index) => (
          <div key={index} className="layer-products__element">
            <ProductItemSkeleton />
          </div>
        ))}
      </div>
    );
  }

  if (layer.products.length === 0) {
    return <EmptyComponent hasFilters={hasFilters} />;
  }

  return (
    <div className="layer-products row start-xxs mt-4">
      {layer.products.map((product, index) => {
        const idx = index + 1;
        const promoteIndex = Number.parseInt(idx / 7 - 1);
        const promoteData = promote?.[promoteIndex];

        stillHavePrismicToDisplay = promoteData !== undefined;

        const shouldDisplayImg =
          stillHavePrismicToDisplay && isMarketingLine(idx);

        return (
          <div
            key={product.sku}
            className={
              stillHavePrismicToDisplay
                ? getLayerProductsElementClassName(idx)
                : "layer-products__element"
            }
          >
            <ProductItem
              key={product.sku}
              product={product}
              {...product}
              products={layer.products}
              location={location}
              index={index}
              breadcrumb={breadcrumb}
              imageSizes={imageSizes}
              imagePriority={index < prioritizeTop}
            />
            {shouldDisplayImg ? (
              <PrismicPromote
                product={product}
                isMarketingLine={isMarketingLine}
                isDesktop={isDesktop}
                promoteData={promoteData}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default compose(EnhanceLayerProducts())(LayerProducts);
