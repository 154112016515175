import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Link from "theme/components/atoms/Typography/Link";
import { H1 } from "theme/components/atoms/Typography/Heading";
import RegisterForm from "theme/modules/User/RegisterForm";
import withLoginRedirection from "theme/pages/Login/withLoginRedirection";
import ExplainedCallToAction from "theme/components/organisms/ExplainedCallToAction";
import qs from "web/core/utils/queryString";
import { useIsModal } from "theme/layouts/ModalContext";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import useRefreshing from "theme/modules/Router/useRefreshing";
import RedirectToLogin from "theme/modules/User/RegisterForm/RedirectToLogin";
import Button from "theme/components/atoms/Button";

const InnerRegister = ({ props }) => {
  const search = qs.parse(props.location?.search);
  const [currentData, setCurrentData] = useState({
    email: search?.email,
  });
  const isModal = useIsModal();
  const [, setRefreshing] = useRefreshing();

  return (
    <RegisterForm
      value={{ email: search?.email }}
      onChange={setCurrentData}
      onLoginSuccess={() => {
        setRefreshing(true);
        window.location.href = props.loginRedirectPath;
        props.clearLoginRedirectPath();
      }}
      additionalActions={
        <>
          {!props.fromPanel ? (
            <RedirectToLogin
              renderLink={({ children }) => {
                return (
                  <Link
                    to={{
                      pathname: "/login",
                      search: qs.stringify({
                        ...search,
                        email: currentData.email,
                      }),
                      state: { modal: isModal },
                    }}
                  >
                    {children}
                  </Link>
                );
              }}
            />
          ) : null}
        </>
      }
    />
  );
};
const Register = (props) => {
  return (
    <div className="page-content">
      {!props.fromPanel ? (
        <div className="ctn">
          <ExplainedCallToAction
            title={
              <H1>
                <FormattedMessage
                  id="pages.Register.title"
                  defaultMessage="Create my account"
                />
              </H1>
            }
          >
            <InnerRegister props={props} />
          </ExplainedCallToAction>
        </div>
      ) : (
        <>
          <InnerRegister props={props} />
          <div className="text-center mt-3">
            <Button appearance="link" onClick={() => props.setOpenState(true)}>
              <FormattedMessage
                id="pages.Register.connectLink"
                defaultMessage="Déjà membre? Se connecter"
              />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default compose(
  withProps((props) => {
    const search = qs.parse(props.location?.search);
    return {
      redirectAfterLogin: search.redirectTo,
    };
  }),
  withLoginRedirection(
    typeof window !== "undefined" ? window : {},
    (props) => props.redirectAfterLogin
  )
)(Register);
