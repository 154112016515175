// antadis : remove H2
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Link from "theme/components/atoms/Typography/Link";

const messages = defineMessages({
  miniCartTitle: {
    id: "modules.Cart.MiniCart.MiniCartContent.title",
    defaultMessage: "My cart",
  },
  productsCount: {
    id: "modules.Cart.MiniCart.MiniCartContent.productsCount",
    defaultMessage: "({qty} {qty, plural, one {product} other {products}})",
  },
});

const MiniCartTitle = ({ cart }) => {
  const intl = useIntl();
  return (
    <div className="mini-cart-title">
      <div>
        <Link to="/cart">
          <span className="mini-cart-title__main">
            {intl.formatMessage(messages.miniCartTitle)}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default MiniCartTitle;
