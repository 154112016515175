import compose from "recompose/compose";
import { graphql } from "react-apollo";
import { injectIntl } from "react-intl";

const EnhanceLayout = ({ NavigationMenuQuery }) =>
  compose(
    injectIntl,
    graphql(NavigationMenuQuery, {
      props: ({ data }) => ({
        categories: {
          ...data,
          footer: null, // remove footer query from categories result
        },
        footerLinks: data.footer,
      }),
    })
  );

export default EnhanceLayout;
