import React, { useRef, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import EnhanceHeader from "./EnhanceHeader";
import HeaderQuery from "./HeaderQuery.gql";
import HeaderUserQuery from "./HeaderUserQuery.gql";
import HeaderOncePerUserQuery from "./HeaderOncePerUserQuery.gql";
import { withShop } from "web/core/shop/ShopContext";
import Link from "theme/components/atoms/Typography/Link";
import Logo from "theme/components/atoms/Logo";
import Navigation from "./Navigation/Navigation";
import useSubNavigation from "./useSubNavigation";
import { withErrorBoundary } from "theme/components/helpers/ErrorBoundary/ErrorBoundary";
import SimpleHeader from "./SimpleHeader";
import TopHeader from "theme/components/organisms/TopHeader";
import { withTopHeaderData } from "theme/modules/Prismic/Blocks/TopHeader";
import Menu from "theme/modules/Prismic/Blocks/Menu/Menu";
import { MiniCartProvider } from "theme/modules/Cart/MiniCart/MiniCartContent/MiniCartContext";
import { withTopHeaderCartData } from "theme/modules/Prismic/Blocks/TopHeaderCart";
import { SearchSuggestionsProvider } from "theme/modules/Prismic/Blocks/SearchSuggestions";
import { useIntl, defineMessages } from "react-intl";
import Panel from "theme/components/molecules/Panel";
import StoreSelectorPanel from "theme/modules/StoreSelector/StoreSelectorPanel";
import Wysiwyg from "theme/modules/WysiwygV2";
import LayoutContext from "../LayoutContext";
import { useDoorService } from "theme/components/helpers/doorService";
import useMediaQueryProps from "theme/components/helpers/useMediaQueryProps";
import { useShop } from "web/core/shop/ShopContext";

import { getJsonParsedString } from "theme/components/helpers/tools.js";
import {
  checkTimestampValidity,
  getFrenchArticleForCountry,
  getCountryTranslation,
} from "./headerUtils.js";

import {
  useGetStoreForCountryAndLanguage,
  useCurrentLocale,
} from "theme/modules/StoreSelector/storeSelectorUtils";

const panelTexts = defineMessages({
  title: {
    id: "layout.Header.geoTitle",
    defaultMessage: "Delivery area & language",
  },
  description: {
    id: "layout.Header.geoDescription",
    defaultMessage:
      "Location selection allows Jérôme Dreyfuss to deliver to you the best services with respect to your localisation.",
  },
});

const geoLocMessage = defineMessages({
  chooseSiteMessage: {
    id: "layout.Header.chooseSiteMessage",
    defaultMessage:
      "You are visiting us from {countryWithArticle}, would you like to go to our {country} website ? ",
  },
  chooseSiteValidateButton: {
    id: "layout.Header.chooseSiteValidateButton",
    defaultMessage: "Yes",
  },
  chooseSiteSelectButton: {
    id: "layout.Header.chooseSiteSelectButton",
    defaultMessage: "No, select a website",
  },
});

const Header = (props) => {
  const [subNavRef, selectMenu, renderSubNav] = useSubNavigation();

  const ref = useRef();
  const shop = useShop();
  const { isServer } = useMediaQueryProps();
  const openMenuState = useDoorService();
  const { topHeader } = props;
  const { setMarginTop, storeSelectorPanelState } = useContext(LayoutContext);

  const intl = useIntl();
  useEffect(() => {
    changeMarginTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    displayTopHeader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  const [topHeaderReady, displayTopHeader] = useState(false);

  const topHeaderMessages = topHeader.content
    ? [
        {
          content: topHeader.content && <Wysiwyg content={topHeader.content} />,
          timer: null,
        },
      ]
    : [];

  // geo information treatment
  const savedGeoInformation = !isServer
    ? localStorage.getItem("savedGeoInformation")
    : null;

  const geoInformation =
    savedGeoInformation === null
      ? props.oncePerUserData?.geolocateIp
      : getJsonParsedString(savedGeoInformation);

  if (
    geoInformation !== null &&
    typeof geoInformation === "object" &&
    geoInformation?.ip
  ) {
    const now = new Date().getTime();

    const expiration = geoInformation?.timestamp
      ? !checkTimestampValidity(geoInformation.timestamp)
      : false;

    if (!savedGeoInformation) {
      geoInformation.timestamp = now;
      localStorage.setItem(
        "savedGeoInformation",
        JSON.stringify(geoInformation)
      );
    }
    if (expiration) {
      localStorage.removeItem("savedGeoInformation");
    }
  }
  const userCountry = geoInformation?.country_code2;
  const userLanguage = shop?.locale ?? "fr-FR";
  const shouldDisplayLastEntry =
    shop.id === "fr_fr" || shop.id === "fr_en" || shop.id === "fr_de";

  const userCountryName = getCountryTranslation(
    userCountry,
    userLanguage?.slice(0, 2)
  );

  const frenchUserCountryName =
    getFrenchArticleForCountry(userCountry) + userCountryName;

  const websiteCountryAndLanguageCode = props.shop?.id;

  const countries = props.data?.countryListWithStores
    ? props.data?.countryListWithStores
    : null;

  const getStoreForUserChoices = useGetStoreForCountryAndLanguage(
    countries || [],
    useCurrentLocale()
  );

  const userCountryAndLanguageCode =
    countries && userCountry && userLanguage
      ? getStoreForUserChoices(userCountry, userLanguage)
      : null;

  const checkCountriesAndLangMatch = () => {
    if (!userCountryAndLanguageCode) return false;
    return userCountryAndLanguageCode !== websiteCountryAndLanguageCode;
  };

  const chooseCountryAndLanguage = () => {
    const path =
      props.shop.url.replace(props.shop.id, "") +
      userCountryAndLanguageCode +
      "/";
    localStorage.setItem("currentCountry", userCountry);
    window.location.href = path;
  };

  const LocationMessage = () => {
    return (
      <>
        {intl.formatMessage(geoLocMessage.chooseSiteMessage, {
          countryWithArticle:
            userLanguage === "fr-FR" ? frenchUserCountryName : userCountryName,
          country: userCountryName,
        })}
        <span className="buttons">
          <span
            className="btn"
            onClick={() => {
              chooseCountryAndLanguage(userCountry, userLanguage);
            }}
          >
            {intl.formatMessage(geoLocMessage.chooseSiteValidateButton)}
          </span>
          <span className="btn" onClick={openEdit}>
            {intl.formatMessage(geoLocMessage.chooseSiteSelectButton)}
          </span>
        </span>
      </>
    );
  };

  if (checkCountriesAndLangMatch()) {
    topHeaderMessages.unshift({
      content: <LocationMessage />,
      timer: 8000,
    });
  }

  if (typeof document !== "undefined") {
    document.documentElement.classList.remove("html-subnav--opened");
  }

  const changeMarginTop = () => {
    setMarginTop(ref.current.clientHeight);
  };

  const openEdit = () => {
    storeSelectorPanelState.open();
  };

  return (
    <>
      <header ref={ref} className="header header__default">
        {topHeaderReady && topHeaderMessages[0] && (
          <TopHeader
            messages={topHeaderMessages}
            countries={countries}
            openEdit={openEdit}
            changeMarginTop={changeMarginTop}
          />
        )}
        <div className="header__container ctn-fluid">
          <div className="header__default__row row middle-xxs">
            <div className="header__logo xxs8 sm4">
              <Link to="/" onClick={() => selectMenu(null)}>
                <Logo />
              </Link>
            </div>
            <div
              className={`header__right xxs4 ${
                shouldDisplayLastEntry ? "sm8" : "sm7"
              }`}
            >
              <div className="row middle-xxs">
                <div className="header__mainmenu xxs xs grow0">
                  <Menu
                    data={props.data}
                    openMenu={openMenuState}
                    userData={props.userData}
                    selectMenu={selectMenu}
                    renderSubNav={renderSubNav}
                    shouldDisplayLastEntry={shouldDisplayLastEntry}
                  />
                </div>
                <div className="header__links xxs xs grow0">
                  <MiniCartProvider
                    value={{
                      miniCartEmptyContent: props.miniCartEmptyContent,
                      topHeaderCartQuery: props.topHeaderCartQuery,
                      cartMessageQuery: props.data.cartMessage,
                      cartMessageSubtotalQuery: props.data.cartMessageSubtotal,
                    }}
                  >
                    <SearchSuggestionsProvider
                      value={props.data.prismicSearchSuggestions}
                    >
                      <Navigation
                        data={props.data}
                        userData={props.userData}
                        selectMenu={selectMenu}
                        renderSubNav={renderSubNav}
                        openMenu={openMenuState}
                      />
                    </SearchSuggestionsProvider>
                  </MiniCartProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="header__sub" ref={subNavRef}></div>
      <Panel
        open={storeSelectorPanelState.state}
        onClose={() => storeSelectorPanelState.close()}
        title={intl.formatMessage(panelTexts.title)}
      >
        <div className="top-header__panel">
          <p className="top-header__panel__description">
            {intl.formatMessage(panelTexts.description)}
          </p>
          <div className="top-header__panel__form">
            {countries && <StoreSelectorPanel countries={countries} />}
          </div>
        </div>
      </Panel>
    </>
  );
};

const SmartHeader = compose(
  withErrorBoundary(SimpleHeader),
  withShop(),
  EnhanceHeader({
    HeaderQuery,
    HeaderUserQuery,
    HeaderOncePerUserQuery,
  }),
  withTopHeaderData(),
  withTopHeaderCartData()
)(Header);

SmartHeader.propTypes = {
  categories: PropTypes.array,
  user: PropTypes.object,
};

export default SmartHeader;
