import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import branchServerClient from "web/core/branchServerClient";

const loadingCart = {
  cart: {},
  loading: true,
  error: null,
};

const EnhanceMiniCartContent = ({ CartQuery }) =>
  compose(
    branchServerClient(
      withProps(loadingCart),
      graphql(CartQuery, {
        props: ({ data }) => {
          if (data.loading) {
            return loadingCart;
          }
          let error = data.error;
          if (!error && !data.cart) {
            error = new Error("no cart was returned");
          }
          return {
            cart: !data.cart ? {} : data.cart,
            loading: false,
            error: error,
          };
        },
      })
    )
  );

export default EnhanceMiniCartContent;
