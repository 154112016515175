// antadis : chge appearance panel cart (not aside)
import React, { useState } from "react";
import compose from "recompose/compose";
import { IconButton } from "theme/components/atoms/Button";
import SearchNavigation from "./SearchNavigation";
import CartNavigation from "./CartNavigation";
import AccountNavigation from "./AccountNavigation";
import SubNavigation from "./SubNavigation";
import { useIntl, defineMessages } from "react-intl";
import withIsFeatureActive from "web/core/UNSAFE_featureFlags/withIsFeatureActive";
import Button from "theme/components/atoms/Button";
import IconWithNotification from "theme/components/molecules/IconWithNotification";
import Icon from "theme/components/atoms/Icon";
import Link from "theme/components/atoms/Typography/Link";
import { trackEvent } from "web/core/analytics";
import { useIsNoEshop } from "theme/components/helpers/shop";

const messages = defineMessages({
  search: {
    id: "layouts.Header.Navigation.search",
    defaultMessage: "Search",
  },
  cart: {
    id: "layouts.Header.Navigation.cart",
    defaultMessage: "My Cart",
  },
  cartLength: {
    id: "layouts.Header.Navigation.cartLength",
    defaultMessage: "{quantity, plural, =1 {1 product} other {# products}}",
  },
  menu: {
    id: "layouts.Header.Navigation.menu",
    defaultMessage: "Menu",
  },
});

const Navigation = ({
  searchFeature,
  wishlistFeature,
  data,
  userData,
  selectMenu,
  renderSubNav,
  openMenu,
}) => {
  const intl = useIntl();
  const [inverted, setInverted] = useState(false);
  const cartLength = userData?.cart?.items_qty ?? null;

  const openSearch = () => {
    selectMenu("search");
    trackEvent("ViewSearchBar");
  };
  const openGlobalMenu = () => {
    openMenu.open();
  };

  return (
    <nav className="navigation">
      <ul className="navigation__list">
        {!searchFeature.loading && searchFeature.isFeatureActive ? (
          <li className="navigation__item navigation__item__search">
            <IconButton
              icon="search"
              title={intl.formatMessage(messages.search)}
              onClick={() => openSearch()}
            />
            {renderSubNav("search")(
              <SubNavigation
                inverted={inverted}
                onClose={() => selectMenu(null)}
              >
                <SearchNavigation setInverted={setInverted} />
              </SubNavigation>
            )}
          </li>
        ) : null}
        {!wishlistFeature.loading && wishlistFeature.isFeatureActive ? (
          <li className="navigation__item desktop-only">
            <Link buttonAppearance="icon" to="/user/wishlist">
              <Icon icon="heart" title={intl.formatMessage(messages.search)} />
            </Link>
          </li>
        ) : null}
        <li className="navigation__item desktop-only">
          <AccountNavigation
            loading={userData?.loading ?? true}
            user={userData?.me}
            selectMenu={selectMenu}
            renderSubNav={renderSubNav}
          />
        </li>
        {!useIsNoEshop() && (
          <li className="navigation__item">
            <Button
              onClick={() => selectMenu("cart")}
              appearance={"icon"}
              title={intl.formatMessage(messages.cart)}
            >
              <IconWithNotification
                icon="cart"
                title={intl.formatMessage(messages.cart)}
                notification={cartLength}
                notificationMessage={intl.formatMessage(messages.cartLength, {
                  quantity: cartLength,
                })}
              />
            </Button>
            {renderSubNav("cart")(
              <SubNavigation onClose={() => selectMenu(null)}>
                <CartNavigation />
              </SubNavigation>
            )}
          </li>
        )}
        <li className="navigation__item navigation__item__menu mobile-only">
          <IconButton
            icon="menu"
            title={intl.formatMessage(messages.menu)}
            onClick={openGlobalMenu}
          />
        </li>
      </ul>
    </nav>
  );
};

export default compose(
  withIsFeatureActive("searchFeature", "search"),
  withIsFeatureActive("wishlistFeature", "wishlist")
)(Navigation);
