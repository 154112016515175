export const FrenchIsocodeMapping = [
  {
    article: "l’",
    iso_code: "AF",
    country_name: "Afghanistan",
  },
  {
    article: "l’",
    iso_code: "AL",
    country_name: "Albanie",
  },
  {
    article: "l’",
    iso_code: "DZ",
    country_name: "Algérie",
  },
  {
    article: "l’",
    iso_code: "DE",
    country_name: "Allemagne",
  },
  {
    article: "l’",
    iso_code: "AD",
    country_name: "Andorre",
  },
  {
    article: "l’",
    iso_code: "AO",
    country_name: "Angola",
  },
  {
    article: "l’",
    iso_code: "SA",
    country_name: "Arabie saoudite",
  },
  {
    article: "l’",
    iso_code: "AR",
    country_name: "Argentine",
  },
  {
    article: "l’",
    iso_code: "AM",
    country_name: "Arménie",
  },
  {
    article: "l’",
    iso_code: "AU",
    country_name: "Australie",
  },
  {
    article: "l’",
    iso_code: "AT",
    country_name: "Autriche",
  },
  {
    article: "l’",
    iso_code: "AZ",
    country_name: "Azerbaïdjan",
  },
  {
    article: "les ",
    iso_code: "BS",
    country_name: "Bahamas",
  },
  {
    article: "le ",
    iso_code: "BH",
    country_name: "Bahreïn",
  },
  {
    article: "le ",
    iso_code: "BD",
    country_name: "Bangladesh",
  },
  {
    article: "la ",
    iso_code: "BB",
    country_name: "Barbade",
  },
  {
    article: "le ",
    iso_code: "BY",
    country_name: "Bélarus",
  },
  {
    article: "la ",
    iso_code: "BE",
    country_name: "Belgique",
  },
  {
    article: "le ",
    iso_code: "BZ",
    country_name: "Bélize",
  },
  {
    article: "le ",
    iso_code: "BJ",
    country_name: "Bénin",
  },
  {
    article: "le ",
    iso_code: "BT",
    country_name: "Bhoutan",
  },
  {
    article: "la ",
    iso_code: "MM",
    country_name: "Birmanie",
  },
  {
    article: "la ",
    iso_code: "BO",
    country_name: "Bolivie",
  },
  {
    article: "la ",
    iso_code: "BA",
    country_name: "Bosnie-Herzégovine",
  },
  {
    article: "le ",
    iso_code: "BW",
    country_name: "Botswana",
  },
  {
    article: "le ",
    iso_code: "BR",
    country_name: "Brésil",
  },
  {
    article: "le ",
    iso_code: "BN",
    country_name: "Brunéi Darussalam",
  },
  {
    article: "la ",
    iso_code: "BG",
    country_name: "Bulgarie",
  },
  {
    article: "le ",
    iso_code: "BF",
    country_name: "Burkina Faso",
  },
  {
    article: "le ",
    iso_code: "BI",
    country_name: "Burundi",
  },
  {
    article: "le ",
    iso_code: "CM",
    country_name: "Cameroun",
  },
  {
    article: "le ",
    iso_code: "CA",
    country_name: "Canada",
  },
  {
    article: "le ",
    iso_code: "CL",
    country_name: "Chili",
  },
  {
    article: "la ",
    iso_code: "CN",
    country_name: "Chine",
  },
  {
    article: "",
    iso_code: "CY",
    country_name: "Chypre",
  },
  {
    article: "la ",
    iso_code: "CO",
    country_name: "Colombie",
  },
  {
    article: "les ",
    iso_code: "KM",
    country_name: "Comores",
  },
  {
    article: "le ",
    iso_code: "CG",
    country_name: "Congo",
  },
  {
    article: "la ",
    iso_code: "KP",
    country_name: "Corée du Nord",
  },
  {
    article: "la ",
    iso_code: "KR",
    country_name: "Corée du Sud",
  },
  {
    article: "le ",
    iso_code: "CR",
    country_name: "Costa Rica",
  },
  {
    article: "la ",
    iso_code: "CI",
    country_name: "Côte d’Ivoire",
  },
  {
    article: "la ",
    iso_code: "HR",
    country_name: "Croatie",
  },
  {
    article: "",
    iso_code: "CU",
    country_name: "Cuba",
  },
  {
    article: "le ",
    iso_code: "DK",
    country_name: "Danemark",
  },
  {
    article: "",
    iso_code: "DJ",
    country_name: "Djibouti",
  },
  {
    article: "la ",
    iso_code: "DM",
    country_name: "Dominique",
  },
  {
    article: "l’",
    iso_code: "EG",
    country_name: "Égypte",
  },
  {
    article: "les ",
    iso_code: "AE",
    country_name: "Émirats arabes unis",
  },
  {
    article: "l’",
    iso_code: "EC",
    country_name: "Équateur",
  },
  {
    article: "l’",
    iso_code: "ER",
    country_name: "Érythrée",
  },
  {
    article: "l’",
    iso_code: "ES",
    country_name: "Espagne",
  },
  {
    article: "l’",
    iso_code: "EE",
    country_name: "Estonie",
  },
  {
    article: "les ",
    iso_code: "US",
    country_name: "États-Unis d’Amérique",
  },
  {
    article: "l’",
    iso_code: "ET",
    country_name: "Éthiopie",
  },
  {
    article: "les ",
    iso_code: "FJ",
    country_name: "Fidji",
  },
  {
    article: "la ",
    iso_code: "FI",
    country_name: "Finlande",
  },
  {
    article: "la ",
    iso_code: "FR",
    country_name: "France",
  },
  {
    article: "le ",
    iso_code: "GA",
    country_name: "Gabon",
  },
  {
    article: "la ",
    iso_code: "GM",
    country_name: "Gambie",
  },
  {
    article: "la ",
    iso_code: "GE",
    country_name: "Géorgie",
  },
  {
    article: "le ",
    iso_code: "GH",
    country_name: "Ghana",
  },
  {
    article: "la ",
    iso_code: "GR",
    country_name: "Grèce",
  },
  {
    article: "la ",
    iso_code: "GD",
    country_name: "Grenade",
  },
  {
    article: "le ",
    iso_code: "GT",
    country_name: "Guatemala",
  },
  {
    article: "la ",
    iso_code: "GN",
    country_name: "Guinée",
  },
  {
    article: "la ",
    iso_code: "GW",
    country_name: "Guinée-Bissau",
  },
  {
    article: "la ",
    iso_code: "GQ",
    country_name: "Guinée équatoriale",
  },
  {
    article: "le ",
    iso_code: "GY",
    country_name: "Guyana",
  },
  {
    article: "",
    iso_code: "HT",
    country_name: "Haïti",
  },
  {
    article: "le ",
    iso_code: "HN",
    country_name: "Honduras",
  },
  {
    article: "la ",
    iso_code: "HU",
    country_name: "Hongrie",
  },
  {
    article: "la ",
    iso_code: "MU",
    country_name: "Île Maurice",
  },
  {
    article: "les ",
    iso_code: "MH",
    country_name: "Îles Marshall",
  },
  {
    article: "les ",
    iso_code: "SB",
    country_name: "Îles Salomon",
  },
  {
    article: "l’",
    iso_code: "IN",
    country_name: "Inde",
  },
  {
    article: "l’",
    iso_code: "ID",
    country_name: "Indonésie",
  },
  {
    article: "l’",
    iso_code: "IR",
    country_name: "Iran",
  },
  {
    article: "l’",
    iso_code: "IQ",
    country_name: "Iraq",
  },
  {
    article: "l’",
    iso_code: "IE",
    country_name: "Irlande",
  },
  {
    article: "l’",
    iso_code: "IS",
    country_name: "Islande",
  },
  {
    article: "",
    iso_code: "IL",
    country_name: "Israël",
  },
  {
    article: "l’",
    iso_code: "IT",
    country_name: "Italie",
  },
  {
    article: "la ",
    iso_code: "JM",
    country_name: "Jamaïque",
  },
  {
    article: "le ",
    iso_code: "JP",
    country_name: "Japon",
  },
  {
    article: "la ",
    iso_code: "JO",
    country_name: "Jordanie",
  },
  {
    article: "le ",
    iso_code: "KZ",
    country_name: "Kazakhstan",
  },
  {
    article: "le ",
    iso_code: "KE",
    country_name: "Kenya",
  },
  {
    article: "",
    iso_code: "KI",
    country_name: "Kiribati",
  },
  {
    article: "le ",
    iso_code: "KG",
    country_name: "Kirghizistan",
  },
  {
    article: "le ",
    iso_code: "",
    country_name: "Kosovo",
  },
  {
    article: "le ",
    iso_code: "KW",
    country_name: "Koweït",
  },
  {
    article: "le ",
    iso_code: "la ",
    country_name: "Laos",
  },
  {
    article: "le ",
    iso_code: "LS",
    country_name: "Lesotho",
  },
  {
    article: "la ",
    iso_code: "LV",
    country_name: "Lettonie",
  },
  {
    article: "le ",
    iso_code: "LB",
    country_name: "Liban",
  },
  {
    article: "le ",
    iso_code: "LR",
    country_name: "Libéria",
  },
  {
    article: "la ",
    iso_code: "LY",
    country_name: "Libye",
  },
  {
    article: "le ",
    iso_code: "LI",
    country_name: "Liechtenstein",
  },
  {
    article: "la ",
    iso_code: "LT",
    country_name: "Lituanie",
  },
  {
    article: "le ",
    iso_code: "LU",
    country_name: "Luxembourg",
  },
  {
    article: "la ",
    iso_code: "MK",
    country_name: "Macédoine",
  },
  {
    article: "",
    iso_code: "MG",
    country_name: "Madagascar",
  },
  {
    article: "la ",
    iso_code: "MY",
    country_name: "Malaisie",
  },
  {
    article: "le ",
    iso_code: "MW",
    country_name: "Malawi",
  },
  {
    article: "les ",
    iso_code: "MV",
    country_name: "Maldives",
  },
  {
    article: "le ",
    iso_code: "ML",
    country_name: "Mali",
  },
  {
    article: "",
    iso_code: "MT",
    country_name: "Malte",
  },
  {
    article: "le ",
    iso_code: "MA",
    country_name: "Maroc",
  },
  {
    article: "la ",
    iso_code: "MR",
    country_name: "Mauritanie",
  },
  {
    article: "le ",
    iso_code: "MX",
    country_name: "Mexique",
  },
  {
    article: "la ",
    iso_code: "FM",
    country_name: "Micronésie",
  },
  {
    article: "la ",
    iso_code: "MD",
    country_name: "Moldavie",
  },
  {
    article: "",
    iso_code: "MC",
    country_name: "Monaco",
  },
  {
    article: "le ",
    iso_code: "ME",
    country_name: "Monténégro",
  },
  {
    article: "le ",
    iso_code: "MZ",
    country_name: "Mozambique",
  },
  {
    article: "la ",
    iso_code: "NA",
    country_name: "Namibie",
  },
  {
    article: "",
    iso_code: "NR",
    country_name: "Nauru",
  },
  {
    article: "le ",
    iso_code: "NP",
    country_name: "Népal",
  },
  {
    article: "le ",
    iso_code: "NI",
    country_name: "Nicaragua",
  },
  {
    article: "le ",
    iso_code: "NE",
    country_name: "Niger",
  },
  {
    article: "le ",
    iso_code: "NG",
    country_name: "Nigéria",
  },
  {
    article: "la ",
    iso_code: "NO",
    country_name: "Norvège",
  },
  {
    article: "la ",
    iso_code: "NZ",
    country_name: "Nouvelle-Zélande",
  },
  {
    article: "",
    iso_code: "OM",
    country_name: "Oman",
  },
  {
    article: "l’",
    iso_code: "UG",
    country_name: "Ouganda",
  },
  {
    article: "l’",
    iso_code: "UZ",
    country_name: "Ouzbékistan",
  },
  {
    article: "le ",
    iso_code: "PK",
    country_name: "Pakistan",
  },
  {
    article: "les ",
    iso_code: "PW",
    country_name: "Palaos",
  },
  {
    article: "le ",
    iso_code: "PA",
    country_name: "Panamá",
  },
  {
    article: "la ",
    iso_code: "PG",
    country_name: "Papouasie-Nouvelle-Guinée",
  },
  {
    article: "le ",
    iso_code: "PY",
    country_name: "Paraguay",
  },
  {
    article: "les ",
    iso_code: "NL",
    country_name: "country_name-Bas",
  },
  {
    article: "le ",
    iso_code: "PE",
    country_name: "Pérou",
  },
  {
    article: "les ",
    iso_code: "PH",
    country_name: "Philippines",
  },
  {
    article: "la ",
    iso_code: "PL",
    country_name: "Pologne",
  },
  {
    article: "le ",
    iso_code: "PT",
    country_name: "Portugal",
  },
  {
    article: "le ",
    iso_code: "QA",
    country_name: "Qatar",
  },
  {
    article: "la ",
    iso_code: "CF",
    country_name: "République centrafricaine",
  },
  {
    article: "la ",
    iso_code: "CD",
    country_name: "République démocratique du Congo",
  },
  {
    article: "la ",
    iso_code: "DO",
    country_name: "République dominicaine",
  },
  {
    article: "la ",
    iso_code: "",
    country_name: "République tchèque",
  },
  {
    article: "la ",
    iso_code: "RO",
    country_name: "Roumanie",
  },
  {
    article: "le ",
    iso_code: "GB",
    country_name: "Royaume-Uni",
  },
  {
    article: "la ",
    iso_code: "RU",
    country_name: "Russie",
  },
  {
    article: "le ",
    iso_code: "RW",
    country_name: "Rwanda",
  },
  {
    article: "",
    iso_code: "",
    country_name: "Saint-Kitts-et-Nevis",
  },
  {
    article: "",
    iso_code: "",
    country_name: "Sainte-Lucie",
  },
  {
    article: "",
    iso_code: "",
    country_name: "Saint-Marin",
  },
  {
    article: "",
    iso_code: "VC",
    country_name: "Saint-Vincent-et-les Grenadines",
  },
  {
    article: "le ",
    iso_code: "",
    country_name: "Saint-Siège",
  },
  {
    article: "le ",
    iso_code: "SV",
    country_name: "Salvador",
  },
  {
    article: "le ",
    iso_code: "WS",
    country_name: "Samoa",
  },
  {
    article: "",
    iso_code: "ST",
    country_name: "Sao Tomé-et-Principe",
  },
  {
    article: "le ",
    iso_code: "SN",
    country_name: "Sénégal",
  },
  {
    article: "la ",
    iso_code: "",
    country_name: "Serbie",
  },
  {
    article: "les ",
    iso_code: "SC",
    country_name: "Seychelles",
  },
  {
    article: "la ",
    iso_code: "SL",
    country_name: "Sierra Leone",
  },
  {
    article: "",
    iso_code: "SG",
    country_name: "Singapour",
  },
  {
    article: "la ",
    iso_code: "SK",
    country_name: "Slovaquie",
  },
  {
    article: "la ",
    iso_code: "SI",
    country_name: "Slovénie",
  },
  {
    article: "la ",
    iso_code: "SO",
    country_name: "Somalie",
  },
  {
    article: "le ",
    iso_code: "SD",
    country_name: "Soudan",
  },
  {
    article: "le ",
    iso_code: "SD",
    country_name: "Soudan du Sud",
  },
  {
    article: "le ",
    iso_code: "LK",
    country_name: "Sri Lanka",
  },
  {
    article: "la ",
    iso_code: "SE",
    country_name: "Suède",
  },
  {
    article: "la ",
    iso_code: "CH",
    country_name: "Suisse",
  },
  {
    article: "le ",
    iso_code: "SR",
    country_name: "Suriname",
  },
  {
    article: "le ",
    iso_code: "SZ",
    country_name: "Swaziland",
  },
  {
    article: "la ",
    iso_code: "SY",
    country_name: "Syrie",
  },
  {
    article: "le ",
    iso_code: "TJ",
    country_name: "Tadjikistan",
  },
  {
    article: "",
    iso_code: "TW",
    country_name: "Taïwan",
  },
  {
    article: "la ",
    iso_code: "TZ",
    country_name: "Tanzanie",
  },
  {
    article: "le ",
    iso_code: "TD",
    country_name: "Tchad",
  },
  {
    article: "la ",
    iso_code: "TH",
    country_name: "Thaïlande",
  },
  {
    article: "le ",
    iso_code: "TL",
    country_name: "Timor-Oriental",
  },
  {
    article: "le ",
    iso_code: "TG",
    country_name: "Togo",
  },
  {
    article: "les ",
    iso_code: "TO",
    country_name: "Tonga",
  },
  {
    article: "",
    iso_code: "TT",
    country_name: "Trinité-et-Tobago",
  },
  {
    article: "la ",
    iso_code: "TN",
    country_name: "Tunisie",
  },
  {
    article: "la ",
    iso_code: "TR",
    country_name: "Turquie",
  },
  {
    article: "le ",
    iso_code: "TM",
    country_name: "Turkménistan",
  },
  {
    article: "",
    iso_code: "TV",
    country_name: "Tuvalu",
  },
  {
    article: "l’",
    iso_code: "UA",
    country_name: "Ukraine",
  },
  {
    article: "l’",
    iso_code: "UY",
    country_name: "Uruguay",
  },
  {
    article: "",
    iso_code: "VU",
    country_name: "Vanuatu",
  },
  {
    article: "le ",
    iso_code: "VE",
    country_name: "Venezuela",
  },
  {
    article: "le ",
    iso_code: "VN",
    country_name: "Vietnam",
  },
  {
    article: "le ",
    iso_code: "YE",
    country_name: "Yémen",
  },
  {
    article: "la ",
    iso_code: "ZM",
    country_name: "Zambie",
  },
  {
    article: "le ",
    iso_code: "ZW",
    country_name: "Zimbabwe",
  },
];
