import React from "react";
import PropTypes from "prop-types";
import LinkList from "theme/components/molecules/LinkList";
import formatFooterLinks from "./formatFooterLinks";
import Link, { OptionalLink } from "theme/components/atoms/Typography/Link/";
import Newsletter from "theme/modules/Newsletter";
import Copyright from "theme/layouts/Footer/Copyright";
import StoreSelector from "theme/modules/StoreSelector";

const BottomBar = ({ footerLinks, countries }) => {
  const bottomLinks = formatFooterLinks(footerLinks?.bottom);
  const leftLinks = formatFooterLinks(footerLinks?.left);

  return (
    <div className="bottombar ctn-fluid mt-5 mt-md-6">
      <div className="row">
        <div className="bottombar__newsletter xxs12 md4">
          <Newsletter />
          <Copyright />
        </div>
        <div className="bottombar__links xxs12 md8">
          <div className="row">
            <div className="xxs12 md6">
              <div className="row start-xxs">
                <div className="bottombar__list xxs nogrow xs6 pr-9">
                  {footerLinks && <LinkList title={""} list={leftLinks} />}
                </div>
                <div className="bottombar__list xxs xs6">
                  {footerLinks && (
                    <LinkList
                      title={""}
                      list={formatFooterLinks(footerLinks?.right)}
                    />
                  )}
                </div>
                <ul className="bottombar__sublist xxs12 xs12">
                  {bottomLinks
                    ? bottomLinks.map((el, i) => (
                        <li key={`bottomlink-${i}`}>
                          <OptionalLink to={el.path}>{el.name}</OptionalLink>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            </div>
            <div className="bottombar__socials xxs12 md4">
              <div className="bottombar__languages">
                {Array.isArray(countries) && (
                  <StoreSelector countries={countries} />
                )}
              </div>
              <ul className="bottombar__socials__links">
                <li>
                  <Link to="https://www.facebook.com/Jerome.Dreyfuss">
                    <i className="icon icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/jeromedreyfuss/?hl=fr">
                    <i className="icon icon-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.pinterest.fr/jeromedreyfuss/_created/">
                    <i className="icon icon-pinterest"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BottomBar.propTypes = {
  categories: PropTypes.object,
  accountDetails: PropTypes.array,
  contactList: PropTypes.array,
};

export default BottomBar;
