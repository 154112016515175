import PropTypes from "prop-types";
import Layout from "theme/layouts/Layout";
import EnhanceLayout from "./EnhanceLayout";
import { compose } from "recompose";
import NavigationMenuQuery from "./NavigationMenuQuery.gql";
import UserQuery from "theme/layouts/Header/UserQuery.gql";
import withModalRoutes from "./withModalRoutes";

const SmartLayout = compose(
  withModalRoutes(),
  EnhanceLayout({
    NavigationMenuQuery,
    UserQuery,
  })
)(Layout);

SmartLayout.propTypes = {
  children: PropTypes.node,
};

export default SmartLayout;
