import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorAlert } from "theme/components/molecules/Alert";

const SearchBarResultsError = () => {
  return (
    <div className="searchbar-results searchbar-results--empty">
      <ErrorAlert>
        <FormattedMessage
          id="modules.Search.SearchBar.SearchBarResults.error"
          defaultMessage="There was an error while loading your search. Please try again or contact us if the problem persists."
        />
      </ErrorAlert>
    </div>
  );
};

export default SearchBarResultsError;
