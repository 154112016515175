import React from "react";
import { OptionalLink } from "theme/components/atoms/Typography/Link/";

const MenuLink = ({
  links,
  index,
  cta,
  additionnal = false,
  setActiveMenu,
  title,
  openMenu,
}) => {
  const linkBlockType = additionnal ? "additionnal" : "children";
  const quitMenu = () => {
    openMenu.close();
    setActiveMenu(null);
  };

  return links.length === 0 ? null : (
    <div
      className={`mainmenu__submenu__${linkBlockType} px-0 pl-md-2  xxs12 xs12${
        !additionnal ? "" : " offset-md4"
      } md4 xl3`}
      key={`${linkBlockType}${index}`}
    >
      <div
        className={`mainmenu__submenu__${linkBlockType}__subtitle h6 color-light`}
      >
        {title}
      </div>
      <ul>
        {links.map((link, index) => (
          <li key={`link${index}`}>
            <div className="mask">
              <OptionalLink to={link.url}>
                <span onClick={quitMenu} style={{ color: link.color }}>
                  {link.label}
                </span>
                {link.flag ? (
                  <sup className="mainmenu__flag h6">{link.flag}</sup>
                ) : null}
              </OptionalLink>
            </div>
          </li>
        ))}
        {cta ? (
          <li className="stared">
            <div className="mask">
              <OptionalLink onClick={quitMenu} to={cta.link}>
                <span onClick={quitMenu}>{cta.label}</span>
              </OptionalLink>
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default MenuLink;
