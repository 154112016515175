import React from "react";
import PropTypes from "prop-types";
import Link from "theme/components/atoms/Typography/Link";
import ListItem from "./ListItem";

const ListDetail = (props) => (
  <ul className="link-list__list">
    {props.list &&
      props.list.map((element, index) => {
        if (!element) {
          return null;
        }
          const path = element.path || "/";
          return (
            <ListItem key={index}>
              <Link key={index} to={path ?? ''}>
                {element.name}
                {props.children}
              </Link>
            </ListItem>
          );
      })}
  </ul>
);

const LinkList = (props) => {
  const listDetail = props.list ? <ListDetail {...props} /> : null;
  return (
    <div className="link-list">
      {props.title ? (
        <div className="link-list__title">{props.title}</div>
      ) : null}
      {listDetail}
    </div>
  );
};

LinkList.propTypes = {
  list: PropTypes.array,
};
export default LinkList;
