import React, { useContext, useEffect, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import getCountryName from "theme/components/atoms/Form/Input/CountrySelect/getCountryName";
import { useShop } from "web/core/shop/ShopContext";
import LayoutContext from "theme/layouts/LayoutContext";
import Icon from "theme/components/atoms/Icon";
import { getCountriesFromStore } from "./storeSelectorUtils";

const messages = defineMessages({
  shipping: {
    id: "modules.StoreSelector.shipping",
    defaultMessage: "Shipping in ",
  },
  chooseACountry: {
    id: "modules.StoreSelector.chooseACountry",
    defaultMessage: "Choose a country",
  },
});

const StoreSelector = ({ countries }) => {
  const shop = useShop();
  const { storeSelectorPanelState } = useContext(LayoutContext);
  const [currentCountryPersistent, setCurrentCountryPersistent] =
    useState("fr");
  const defaultCountryShop = {
    fr: "FR",
    eu: false,
    int: false,
    no: false,
  };

  const intl = useIntl();
  const openEditStore = () => {
    storeSelectorPanelState.open();
  };

  const shop_loc = shop.id.split("_")[0];
  const countriesFromShop = getCountriesFromStore(shop.id, countries);

  useEffect(() => {
    const registeredCountry = localStorage.getItem("currentCountry");
    const currentCountryVerified = countriesFromShop.includes(registeredCountry)
      ? registeredCountry
      : defaultCountryShop[shop_loc];
    setCurrentCountryPersistent(currentCountryVerified);
  });

  return (
    <div className="store-selector row">
      <div
        className="store-selector__btn cursor xxs12 end-xxs"
        onClick={openEditStore}
      >
        <span className="underline">
          {currentCountryPersistent ? (
            <>
              {intl.formatMessage(messages.shipping)}{" "}
              {getCountryName(intl, currentCountryPersistent)} /{" "}
            </>
          ) : (
            <>{intl.formatMessage(messages.chooseACountry)} / </>
          )}
          EURO €
        </span>
        <Icon icon="chevron-right" title="" size="small" />
      </div>
    </div>
  );
};

export default StoreSelector;
