import { compose } from "recompose";
import { graphql } from "react-apollo";

const EnhanceFooter = ({ FooterQuery }) =>
  compose(
    graphql(FooterQuery, {
      name: "footerQuery",
    })
  );

export default EnhanceFooter;
