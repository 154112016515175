import React, { useState, useEffect } from "react";
import { useWindowSize, useMeasure } from "react-use";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import config from "config/website";
import useFullUrl from "web/core/shop/useFullUrl";
import ErrorBoundary from "theme/components/helpers/ErrorBoundary";
import PageError from "theme/modules/PageError";
import useRefreshing from "theme/modules/Router/useRefreshing";
import { LayoutProvider } from "./LayoutContext";
import { useDoorService } from "theme/components/helpers/doorService";

const GenericLayout = ({ header, footer, children, contextClass = "" }) => {
  const intl = useIntl();
  const url = useFullUrl("/");
  const [refreshing] = useRefreshing();
  const windowSize = useWindowSize();
  const [ref, measures] = useMeasure();
  const tallScreen =
    measures.height > windowSize.height * 2 ? "tallScreen" : "";

  const [marginTop, setMarginTop] = useState(0);
  const [cookiesBottom, setCookiesBottom] = useState(0);
  const [ctaFixed, setCtaFixed] = useState(false);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.body.style.marginTop = marginTop + "px";
    }
  }, [marginTop]);

  useEffect(() => {
    setCookiesBottom(0);
  }, [children]);

  return (
    <LayoutProvider
      value={{
        setMarginTop,
        storeSelectorPanelState: useDoorService(),
        cookiesBottom,
        setCookiesBottom,
        ctaFixed,
        setCtaFixed,
      }}
    >
      <div
        className={classnames("wrapper", { "wrapper--refreshing": refreshing })}
        itemScope
        itemType="http://schema.org/WebSite"
      >
        {/* this meta is outside Helmet because it describes the itemType="WebSite" */}
        <meta itemProp="url" content={url} />

        <Helmet defaultTitle={config.defaultTitle}>
          <html lang={intl.locale} className={tallScreen} />
          <meta name="language" content={intl.locale} />
          <meta name="robots" content="Index,Follow" />
          <meta name="description" content={config.defaultDescription} />
          {config.themeColor ? (
            <meta name="theme-color" content={config.themeColor} />
          ) : null}
        </Helmet>
        {header}
        <div className={`ctn-fluid ${contextClass}`} ref={ref}>
          <ErrorBoundary>
            {({ hasError }) => (hasError ? <PageError /> : children)}
          </ErrorBoundary>
        </div>
        {footer}
      </div>
    </LayoutProvider>
  );
};

export default GenericLayout;
