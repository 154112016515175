import React, { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import Logo from "theme/components/atoms/Logo";
import Icon from "theme/components/atoms/Icon";
import { defineMessages, injectIntl } from "react-intl";

const messages = defineMessages({
  need_help: {
    id: "molecules.Card.helpTitle",
    defaultMessage: "Need help ?",
  },
  phone: {
    id: "theme.layouts.Header.phone",
    defaultMessage: "+33 (0)1 56 81 85 30",
  },
  week_open: {
    id: "theme.layouts.Header.week_open",
    defaultMessage: "Lundi au samedi: 10:00-9:00",
  },
  weekend_open: {
    id: "theme.layouts.Header.weekend_open",
    defaultMessage: "Dimanche: 12:00-6:00",
  },
  contact: {
    id: "theme.layouts.Header.contact",
    defaultMessage: "customercare@jeromedreyfuss.com",
  },
  contact_subtitle: {
    id: "theme.layouts.Header.contact_subtitle",
    defaultMessage: "Réponse sous 24h",
  },
});

const OutsideClickHandler = ({ children, onOutsideClick, otherRef }) => {
  const wrapperRef = createRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (otherRef.current && !otherRef.current.contains(event.target)) {
          onOutsideClick();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onOutsideClick, wrapperRef, otherRef]);

  return <div ref={wrapperRef}>{children}</div>;
};

const SimpleHeader = ({ intl }) => {
  const openTel = () => {
    window.open(`tel:${intl.formatMessage(messages.phone)}`);
  };
  const openMail = () => {
    window.open(`mailto:${intl.formatMessage(messages.contact)}`);
  };

  const [showHelp, setShowHelp] = useState(false);
  const wrapperRef = createRef();

  if (typeof document !== "undefined") {
    document.documentElement.classList.remove("html-subnav--opened");
  }

  const popupClass = "header__popup " + (showHelp ? "show" : "");
  const zIndexClass = showHelp ? "zindexhigher" : "";
  const iconClass = showHelp ? "rotate" : "";
  return (
    <>
      <div className={zIndexClass + " header__simple header"}>
        <div className="xs-ctn">
          <div className="header__container mt-md-0 mt-md-8">
            <div className="header__logo flex between-xxs middle-xxs">
              <Link to="/">
                <Logo />
              </Link>
              <div ref={wrapperRef}>
                <div
                  className="header__help flex start-xss"
                  onClick={() => setShowHelp(!showHelp)}
                >
                  <p>{intl.formatMessage(messages.need_help)}</p>
                  <div className={`ml-3`}>
                    <Icon
                      icon="caret"
                      size="mini"
                      title="caret"
                      className={iconClass}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OutsideClickHandler
          otherRef={wrapperRef}
          onOutsideClick={() => setShowHelp(false)}
        >
          <div className={popupClass}>
            <div className="popup_header flex between-xxs">
              <p>{intl.formatMessage(messages.need_help)}</p>
              <button onClick={() => setShowHelp(false)}>
                <Icon icon="close" size="small" title="close" />
              </button>
            </div>
            {/* TODO: traductions */}
            <div className="flex between-xxs p-4">
              <div className="pr-3">
                <Icon icon="phone1" size="small" title="phone1" />
              </div>
              <div className="start-xxs">
                <p className="label pb-2 pointer" onClick={() => openTel()}>
                  {intl.formatMessage(messages.phone)}
                </p>
                <p className="info">{intl.formatMessage(messages.week_open)}</p>
                <p className="info">
                  {intl.formatMessage(messages.weekend_open)}
                </p>
              </div>
            </div>

            <div className="flex between-xxs p-4 md-4">
              <div className="pr-3">
                <Icon icon="envelope" size="small" title="envelope" />
              </div>
              <div className="start-xxs">
                <p className="label pb-2 pointer" onClick={() => openMail()}>
                  {intl.formatMessage(messages.contact)}
                </p>
                <p className="info">
                  {intl.formatMessage(messages.contact_subtitle)}
                </p>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default injectIntl(SimpleHeader);
