import React from "react";
import Wysiwyg from "theme/modules/WysiwygV2";
import { useIsNoEshop } from "theme/components/helpers/shop";

const formatFooterLinks = (links) => {
  return links
    ? links.map((elem) => {
        const name = <Wysiwyg content={elem.name} />;
        const ret = {
          path: elem.path,
          name,
        };
        return !useIsNoEshop() ? ret : elem.isnoeshop ? ret : false;
      })
    : null;
};

export default formatFooterLinks;
