import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router";
import { trackEvent } from "web/core/analytics";

const useSubNavigation = () => {
  const subNavRef = useRef();

  const [selectedMenu, setSelectedMenu] = useState(null);
  const selectMenu = (id) => {
    setSelectedMenu((current) => (current === id ? null : id));
  };

  const renderSubNav = (id) =>
    selectedMenu === id
      ? (element) => createPortal(element, subNavRef.current)
      : () => {};

  const location = useLocation();
  useEffect(() => {
    selectMenu(null);
  }, [location.key]);

  useEffect(() => {
    if (selectedMenu === "login") {
      trackEvent("ViewLogin");
    } else if (selectedMenu === "cart") {
      trackEvent("ViewCart");
    }
  }, [selectedMenu]);

  return [subNavRef, selectMenu, renderSubNav];
};

export default useSubNavigation;
