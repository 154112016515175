import React from "react";
import config from "config/website";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import Subscribe from "./Subscribe";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import Link from "theme/components/atoms/Typography/Link";

const messages = defineMessages({
  policy: {
    id: "modules.Newsletter.policy",
    defaultMessage: "",
  },
});

const Newsletter = props => {
  const intl = useIntl();
  return (
    <div className="newsletter">
      <span className="newsletter__title h4">
        <FormattedMessage
          id="modules.Newsletter.keepInTouch"
          defaultMessage="Subscribe to our newsletter and
            receive exclusive offers every week"
        />
      </span>
      <div className="newsletter__subscribe">
        <Subscribe />
      </div>
      <span className={"legal"}>
        <FormattedMessage
          id="modules.Newsletter.legal"
          defaultMessage=""
          values={{
            policy: (
              <Link to={config.policyUrl}>
                {intl.formatMessage(messages.policy)}
              </Link>
            ),
          }}
        />
      </span>
    </div>
  );
};

export default withHideOnErrorBoundary(Newsletter);
