// USEFUL FUNCTIONS

/**
 * Get the first item of an array if it exists, else get undefined
 * @param {Array}
 */
const getFirstItemInArray = (array) =>
  Array.isArray(array) && array[0] !== undefined ? array[0] : undefined;

/**
 * Check if String can be converted in Json
 * @param {String}
 */
const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Convert String in Json
 * @param {String}
 */
const getJsonParsedString = (str) => {
  return isJsonString(str) ? JSON.parse(str) : str;
};

/**
 * Check if an object is empty
 * @param {Object}
 */
const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export { getFirstItemInArray, getJsonParsedString, isObjectEmpty };
