import { compose, withState } from "recompose";
import { graphql } from "react-apollo";

const homogeneizeSuggestions = (data) => {
  const searchSuggestions = data.searchSuggestions && {
    ...data.searchSuggestions,
    products: data.searchSuggestions.products.products,
  };

  return {
    ...data,
    searchSuggestions,
  };
};

const EnhanceSearchBar = ({ SearchQuery }) =>
  compose(
    withState("search", "setSearch", {}),
    graphql(SearchQuery, {
      skip: ({ search }) => !search.search,
      options: ({ search }) => ({
        variables: {
          query: search.search,
          resultsPerType: 1000
        },
      }),
      props: ({ data }) => ({
        loading: data.loading,
        error: data.error,
        data: homogeneizeSuggestions(data),
      }),
    })
  );

export default EnhanceSearchBar;
