import React from "react";
import PrismicPreview from "theme/modules/Prismic/PrismicPreview";
import SmartLayout from "theme/layouts/SmartLayout";

const Layout = (props) => {
  return (
    <>
      <PrismicPreview />
      <SmartLayout {...props} />
    </>
  );
};

export default Layout;
