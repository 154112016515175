import branchServerClient from "web/core/branchServerClient";
import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import { withShop } from "web/core/shop/ShopContext";
import extractDeliveryZoneFromMagentoShop from "theme/modules/Prismic/extractDeliveryZoneFromMagentoShop";

const removeInvalidCategories = (categories) =>
  categories
    .filter(({ include_in_menu }) => include_in_menu)
    .map((category) => ({
      ...category,
      children: category.children
        ? removeInvalidCategories(category.children)
        : [],
    }));
const savedGeoInformation =
  typeof window === "undefined" || typeof window.document === "undefined"
    ? null
    : localStorage.getItem("savedGeoInformation");
const needQueryTest =
  savedGeoInformation === null &&
  process.env.FRONT_COMMERCE_WEB_USE_IP_LOCATION === "1"
    ? true
    : false;
const EnhanceHeader = ({
  HeaderQuery,
  HeaderUserQuery,
  HeaderOncePerUserQuery,
}) =>
  compose(
    withShop(),
    graphql(HeaderQuery, {
      name: "data",
      options: ({ shop }) => ({
        variables: {
          cartMessageUid: extractDeliveryZoneFromMagentoShop(shop),
          cartMessageSubtotalUid: extractDeliveryZoneFromMagentoShop(shop),
          linkTextUid: "mini_cart_empty",
        },
      }),
    }),
    withProps((props) =>
      props.data
        ? {
            ...props,
            data: {
              ...props.data,
              navigationMenu: props.data.navigationMenu
                ? removeInvalidCategories(props.data.navigationMenu)
                : [],
            },
            miniCartEmptyContent: props.data.linkText,
          }
        : props
    ),
    branchServerClient(
      withProps({
        userData: {},
      }),
      graphql(HeaderUserQuery, {
        name: "userData",
      })
    ),
    branchServerClient(
      withProps({
        oncePerUserData: {},
      }),
      needQueryTest
        ? graphql(HeaderOncePerUserQuery, {
            name: "oncePerUserData",
          })
        : withProps({
            oncePerUserData: {},
          })
    )
  );

export default EnhanceHeader;
