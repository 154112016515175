import React from "react";
import EnhanceFooter from "theme/layouts/Footer/EnhanceFooter";
import BottomBar from "theme/layouts/Footer/BottomBar";
import FooterQuery from "./FooterQuery.gql";
import { compose, lifecycle } from "recompose";
import { initAnalytics } from "web/core/analytics";
import withConsentCookies from "web/core/analytics/withConsentCookies";

const Footer = (props) => {
  return (
    <footer>
      <BottomBar
        categories={props.categories}
        accountDetails={props.accountDetails}
        contact={props.contact}
        user={props.footerQuery.me}
        footerLinks={props.footerLinks}
        countries={props.footerQuery.countryListWithStores}
      />
    </footer>
  );
};

const SmartFooter = EnhanceFooter({ FooterQuery })(Footer);

export default compose(
  withConsentCookies(),
  lifecycle({
    componentDidMount() {
      initAnalytics();
    }
  })
)(SmartFooter);
