import React from "react";
import { FormattedMessage } from "react-intl";

const Copyright = () => (
  <div className="bottombar__logo">
    <i className="icon icon-rivets"></i>
    <FormattedMessage
      id="theme.layouts.Footer.copyright"
      defaultMessage="© Jerôme Dreyfuss"
    />{" "}
    {new Date().getFullYear()}
  </div>
);

export default Copyright;
