import React, { useContext, useEffect } from "react";
import EnhanceSearchBar from "./EnhanceSearchBar";
import SearchBarQuery from "./SearchBarQuery.gql";
import SearchBarResults from "./SearchBarResults";
import useSearchBar from "./useSearchBar";
import SearchBarForm from "./SearchBarForm";
import { defineMessages, useIntl } from "react-intl";
import SearchSuggestions, {
  SearchSuggestionContext,
} from "theme/modules/Prismic/Blocks/SearchSuggestions";
import SearchBarResultsEmpty from "./SearchBarResults/SearchBarResultsEmpty";

const messages = defineMessages({
  title: {
    id: "modules.Search.SearchBar.title",
    defaultMessage: "Search",
  },
  resultLabel: {
    id: "modules.Search.SearchBar.resultLabel",
    defaultMessage: "Results",
  },
});

const SearchBar = ({ search, setSearch, data, loading, error, setInverted }) => {
  const {
    contentRef,
    searchUrl,
    searchTarget,
    onSubmit,
    onChange,
    onFocus,
    showAutocompleteResults,
    isOpen,
    selected,
    onSelect,
  } = useSearchBar({ search, setSearch, data });
  const intl = useIntl();
  const searchSuggestions = useContext(SearchSuggestionContext);

  useEffect(() => {
    setInverted(!!data?.searchSuggestions);
  });

  return (
    <div className={"search-box pb-6"} ref={contentRef}>
      <div className="search-box__title p2 mb-9">
        {intl.formatMessage(messages.title)}
      </div>
      <div className="search-box__form mb-7 mb-md-9">
        <SearchBarForm
          search={search}
          searchTarget={searchTarget}
          data={data}
          selected={selected}
          isExpanded={showAutocompleteResults}
          onSubmit={onSubmit}
          onChange={onChange}
          onFocus={onFocus}
        />
        {data?.searchSuggestions && (
          <div className="row start-xxs">
            <div className="xxs12 md6 mt-5">
              {data.searchSuggestions.products.length}{" "}
              {intl.formatMessage(messages.resultLabel)}
            </div>
            {data?.searchSuggestions?.products.length === 0 && !error && (
              <div className="xxs12 md4 mt-5">
                <SearchBarResultsEmpty />
              </div>
            )}
          </div>
        )}
      </div>
      {searchSuggestions?.linkList?.length && !data?.searchSuggestions && (
        <SearchSuggestions linkList={searchSuggestions.linkList} />
      )}
      {isOpen && (
        <SearchBarResults
          loading={loading ?? true}
          error={error}
          search={search}
          searchUrl={searchUrl}
          results={data?.searchSuggestions}
          selected={selected}
          onSelect={onSelect}
        />
      )}
    </div>
  );
};

export default EnhanceSearchBar({
  SearchQuery: SearchBarQuery,
})(SearchBar);
