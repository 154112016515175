import React, { useContext } from "react";
import MiniCartContext from "./MiniCartContext";
import Wysiwyg from "theme/modules/WysiwygV2";

const MiniCartHeader = () => {
  const messageQuery = useContext(MiniCartContext).topHeaderCartQuery;
  return (
    <div className="row">
      <div className="xxs12 p1">
        {messageQuery?.hasResult() && messageQuery?.data?.message && (
          <Wysiwyg content={messageQuery.data.message} />
        )}
      </div>
    </div>
  );
};

export default MiniCartHeader;
