import React, { useCallback } from "react";
import { useIntl, defineMessages } from "react-intl";
import { IconButton } from "theme/components/atoms/Button";
import Button from "theme/components/atoms/Button";
import { useHistory } from "react-router";
import useDifferedClick from "./useDifferedClick";
import useMediaQueryProps from "theme/components/helpers/useMediaQueryProps";

const loginPath = {
  pathname: "/login",
  state: { modal: true },
};
const accountPath = {
  pathname: "/user",
  state: { modal: false },
};

const messages = defineMessages({
  account: {
    id: "layouts.Header.Navigation.account",
    defaultMessage: "My Account",
  },
  loading: {
    id: "layouts.Header.Navigation.loading",
    defaultMessage: "Loading user information...",
  },
  signin: {
    id: "layouts.Header.Navigation.signin",
    defaultMessage: "Sign in",
  },
});

const AccountIcon = ({ loading, user, onUserMenu, text, openMenu }) => {
  const intl = useIntl();

  const { isDesktop } = useMediaQueryProps();
  const history = useHistory();

  const [isClicked, onLoadingClick] = useDifferedClick(
    loading,
    useCallback(() => {
      if (!user) {
        return false;
      }

      if (user.id) {
        onUserMenu();
      } else {
        history.push(loginPath);
      }
    }, [user, history, onUserMenu])
  );
  const toMyaccount = () => {
    history.push(accountPath);
    if (openMenu !== undefined) {
      openMenu.close();
    }
  };

  return loading ? (
    isDesktop ? (
      <IconButton
        icon="user"
        title={intl.formatMessage(messages.loading)}
        onClick={() => {
          onLoadingClick(true);
        }}
        state={isClicked ? "pending" : undefined}
      />
    ) : (
      <Button
        state={isClicked ? "pending" : undefined}
        onClick={() => {
          onLoadingClick(true);
        }}
      >
        {text}
      </Button>
    )
  ) : isDesktop ? (
    <IconButton
      icon="user"
      title={intl.formatMessage(messages.account)}
      onClick={() => (user.id ? history.push(accountPath) : onUserMenu())}
    />
  ) : (
    <Button onClick={() => (user.id ? toMyaccount() : onUserMenu())}>
      {text}
    </Button>
  );
};

export default AccountIcon;
