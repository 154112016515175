import React, { useRef, useState, useEffect } from "react";
import Icon from "theme/components/atoms/Icon";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";

const TopHeader = (props) => {
  const messages = props.messages;
  const { changeMarginTop } = props;
  const ref = useRef();
  const [showHeader, setShowHeader] = useState(true);
  const [closingheader, setClosingheader] = useState(false);
  const [messageOpacity, setMessageOpacity] = useState(1);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [message, setMessage] = useState(messages[currentMessage] || "");

  const closeHeaderTop = () => {
    setMessageOpacity(0);
    setTimeout(() => {
      setClosingheader(true);
      setTimeout(() => {
        setShowHeader(false);
      }, 300);
      changeMarginTop();
    }, 300);
  };

  const changeMessage = () => {
    let nextMsg = messages[currentMessage + 1];
    if (nextMsg) {
      nextMsg.timer =
        typeof nextMsg.content !== "undefined" ? nextMsg.timer : 1;
      setMessageOpacity(0);
      setTimeout(() => {
        setMessage(nextMsg);
        setCurrentMessage(currentMessage + 1);
        setMessageOpacity(1);
        changeMarginTop();
      }, 300);
    } else {
      closeHeaderTop();
    }
  };

  useEffect(() => {
    changeMarginTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (!message.timer || !message) return;
    const timer = setTimeout(() => {
      changeMessage();
    }, message.timer);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, currentMessage]);

  return (
    <>
      <div
        ref={ref}
        className={`top-header flex px-2 ${closingheader ? "closing" : ""} ${
          !showHeader ? "close" : ""
        }`}
      >
        <div
          className={`top-header__message xxs10 fw p1 py-1 ${
            !props.isMobile ? "text-center" : ""
          }`}
        >
          <span
            className="top-header__message__content"
            style={{
              opacity: messageOpacity,
            }}
          >
            {message.content}
          </span>
        </div>
        <div
          onClick={closeHeaderTop}
          className="top-header__close xxs nogrow py-1"
        >
          <Icon icon="close" title={"close"} size="mini" />
        </div>
      </div>
    </>
  );
};

export default withMediaQueryProps(TopHeader);
