import React, { useState, useEffect } from "react";
import Button from "theme/components/atoms/Button";
import { OptionalLink } from "theme/components/atoms/Typography/Link/";
import { injectIntl } from "react-intl";
import useMediaQueryProps from "theme/components/helpers/useMediaQueryProps";
import MenuLink from "./MenuLink";
import useMenuState from "./useMenuState";
import AccountNavigation from "theme/layouts/Header/Navigation/AccountNavigation";
import { defineMessages, useIntl } from "react-intl";

const Menu = ({
  data,
  openMenu,
  userData,
  selectMenu,
  renderSubNav,
  shouldDisplayLastEntry,
}) => {
  const { isDesktop } = useMediaQueryProps();
  const [activeMenu, setActiveMenu] = useState(null);

  const menuClass = useMenuState(activeMenu);
  const intl = useIntl();
  const messages = defineMessages({
    lastEntry: {
      id: "modules.prismic.menu",
      defaultMessage: "Seconde Main",
    },
  });

  const handleOpenSubMenu = (id) => {
    setActiveMenu(id);
  };

  const handleCloseMenu = () => {
    setActiveMenu(null);
    openMenu.close();
  };

  useEffect(() => {
    if (openMenu.state) {
      document.documentElement.classList.add("html-menu--opened");
    } else {
      document.documentElement.classList.remove("html-menu--opened");
    }
  }, [openMenu.state]);

  return data.menu && data.menu.length ? (
    <nav
      className={`mainmenu py-md-4 ${menuClass} ${
        activeMenu !== null && isDesktop ? " menu-open" : ""
      } ${activeMenu !== null && !isDesktop ? " submenu-open" : ""} ${
        openMenu.state ? "menu-open-mobile" : ""
      }`}
      onMouseLeave={() => (isDesktop ? handleOpenSubMenu(null) : false)}
    >
      <div className="mainmenu__list">
        <ul className="mainmenu__list__main flex between-xxs start-md">
          <li className="mainmenu_close mobile-only">
            <Button
              appearance="link"
              additionnalClasses=" underline-animated-small"
              onClick={() => openMenu.close()}
            >
              <i className="icon icon-close"></i>
            </Button>
          </li>
          {data.menu.map((el, index, arr) => (
            <li
              className={`mainmenu__item h1${
                activeMenu === index ? " active" : ""
              }`}
              key={`menu${index}`}
            >
              <Button
                appearance="link"
                additionnalClasses=" underline-animated-small"
                onClick={() => handleOpenSubMenu(index)}
                onMouseEnter={() =>
                  isDesktop ? handleOpenSubMenu(index) : false
                }
              >
                <span className="mainmenu__item__label">{el.label}</span>
                {index < arr.length - 1 ? (
                  <span className="hide-xxs show-ib-md">,</span>
                ) : shouldDisplayLastEntry ? (
                  <span className="hide-xxs show-ib-md">,</span>
                ) : null}
                <span className="show-ib-xxs hide-md">
                  <i className="icon icon-chevron-right"></i>
                </span>
              </Button>
            </li>
          ))}
          {shouldDisplayLastEntry && (
            <li
              className="mainmenu__item h1"
              onMouseEnter={() => (isDesktop ? handleOpenSubMenu(null) : false)}
            >
              <div className="underline-animated-small">
                <OptionalLink to="https://vintage.jerome-dreyfuss.com/">
                  {intl.formatMessage(messages.lastEntry)}
                </OptionalLink>
              </div>
            </li>
          )}

          {data.menuCta ? (
            <li
              className="mainmenu__item stared"
              onMouseEnter={() => (isDesktop ? handleOpenSubMenu(null) : false)}
            >
              <OptionalLink to={data.menuCta.link}>
                {data.menuCta.label}
              </OptionalLink>
            </li>
          ) : null}
        </ul>
        {!isDesktop && data.menuMobilelinks ? (
          <ul className="mainmenu__list__secondary mobile-only">
            <li onClick={() => openMenu.close()}>
              <AccountNavigation
                loading={userData?.loading ?? true}
                user={userData?.me}
                selectMenu={selectMenu}
                renderSubNav={renderSubNav}
                mobile={true}
                openMenu={openMenu}
              />
            </li>
            {data.menuMobilelinks?.link_1 ? (
              <li onClick={() => openMenu.close()}>
                <OptionalLink to={data.menuMobilelinks?.link_1}>
                  <span onClick={() => setActiveMenu(null)}>
                    {data.menuMobilelinks.link_1_label}
                  </span>
                </OptionalLink>
              </li>
            ) : null}
            {data.menuMobilelinks?.link_2 ? (
              <li onClick={() => openMenu.close()}>
                <OptionalLink to={data.menuMobilelinks?.link_2}>
                  <span onClick={() => setActiveMenu(null)}>
                    {data.menuMobilelinks.link_2_label}
                  </span>
                </OptionalLink>
              </li>
            ) : null}
            {data.menuMobilelinks?.link_3 ? (
              <li onClick={() => openMenu.close()}>
                <OptionalLink to={data.menuMobilelinks?.link_3}>
                  <span onClick={() => setActiveMenu(null)}>
                    {data.menuMobilelinks?.link_3_label}
                  </span>
                </OptionalLink>
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
      <div className="mainmenu__submenu ">
        {data.menu.map((el, index) => {
          return el.children.length || el.additionnallinks.length ? (
            <div
              className={`mainmenu__submenu__ctn row${
                activeMenu === index && openMenu.state && !isDesktop
                  ? " active"
                  : ""
              } ${activeMenu === index && isDesktop ? " active" : ""}`}
              key={`submenu${index}`}
            >
              <div className="mainmenu__submenu__close xxs12">
                <div className="flex middle-xxs fw">
                  <Button
                    onClick={() => handleOpenSubMenu(null)}
                    className="flex"
                  >
                    <i className="show-xxs hide-md mr-3 icon icon-chevron-left"></i>
                    <span className="navigation-m">{el.label}</span>
                  </Button>
                  <Button onClick={() => handleCloseMenu()}>
                    <span className="show-ib-xxs hide-md">
                      <i className="icon icon-close"></i>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="mainmenu__submenu__inner col row-md nowrap start-md">
                <MenuLink
                  links={el.additionnallinks}
                  additionnal={true}
                  setActiveMenu={setActiveMenu}
                  title={el.additionnallinks_title}
                  openMenu={openMenu}
                />
                <MenuLink
                  links={el.children}
                  index={index}
                  cta={data.menuCta}
                  setActiveMenu={setActiveMenu}
                  title={el.childrenlinks_title}
                  openMenu={openMenu}
                />
              </div>
            </div>
          ) : null;
        })}
      </div>
    </nav>
  ) : null;
};

export default injectIntl(Menu);
