// antadis : replace numberInout by select
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Form from "theme/components/atoms/Form/Form";
import { defineMessages, useIntl } from "react-intl";
import Spinner from "theme/components/atoms/Spinner";
import IconButton from "theme/components/atoms/Button/IconButton";
import Select from "theme/components/atoms/Form/Input/Select";
import FormItem from "theme/components/molecules/Form/Item";

const messages = defineMessages({
  refresh: {
    id: "modules.Cart.CartItem.CartItemQuantityForm.refresh",
    defaultMessage: "Refresh the product quantity",
  },
  quantityLabel: {
    id: "modules.Cart.CartItem.CartItemQuantityForm.quantityLabel",
    defaultMessage: "Quantity",
  },
});

const CartItemQuantityForm = (props) => {
  const intl = useIntl();
  const valuesOptions = [];
  for (var i = 0; i <= 50; i++) {
    valuesOptions.push(
      {"value": i, "label": "" + i}
    );
 }
  const numberInput = (
    <Select
      id={"quantity_" + props.item_id}
      name={"quantity"}
      options={valuesOptions}
      value={props.quantity?props.quantity:1}
    />
  );

  return (
    <Form onChange={props.onRefreshQuantity}>
      <div
        className={classNames("cart-quantity-form", {
          [`cart-quantity-form--${props.appearance}`]: props.appearance,
        })}
      >
        {props.refreshQuantityPending ? (
          <Spinner />
        ) : (
          <Fragment>
            {props.appearance === "small" ? (
              <FormItem
                appearance="inline"
                label={intl.formatMessage(messages.quantityLabel)}
              >
                {numberInput}
              </FormItem>
            ) : (
              numberInput
            )}
            <div className="cart-quantity-form__button">
              <IconButton
                icon="sync"
                title={intl.formatMessage(messages.refresh)}
                type="submit"
                state={
                  props.refreshQuantityPending
                    ? "pending"
                    : props.disabled
                    ? "disabled"
                    : undefined
                }
                onDisableClick={() => {}}
              />
            </div>
          </Fragment>
        )}
      </div>
    </Form>
  );
};
CartItemQuantityForm.propTypes = {
  item_id: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  quantity: PropTypes.number.isRequired,
  appearance: PropTypes.oneOf(["default", "small"]),
};

export default CartItemQuantityForm;
