import QueryResult from "theme/modules/GQL/QueryResult";
import { graphql } from "react-apollo";
import TopHeaderCartQuery from "./TopHeaderCartQuery.gql";
import { withShop } from "web/core/shop/ShopContext";
import { compose } from "recompose";
import extractDeliveryZoneFromMagentoShop from "theme/modules/Prismic/extractDeliveryZoneFromMagentoShop";

const withTopHeaderCartData = () =>
  compose(
    withShop(),
    graphql(TopHeaderCartQuery, {
      options: ({ shop }) => ({
        variables: {
          uid: extractDeliveryZoneFromMagentoShop(shop),
        },
      }),
      props: ({ data }) => {
        const topHeaderCartQuery = new QueryResult();
        topHeaderCartQuery.initFromRecompose({ data }, data.topHeaderCart);
        return {
          topHeaderCartQuery,
        };
      },
    })
  );

export default withTopHeaderCartData;
