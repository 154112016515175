export default {
  fr_fr: [
    {
      title: "Cookie de tracking",
      description:
        "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  fr_en: [
    {
      title: "Tracking cookie",
      description:
        "The audience measurement services used to generate useful statistics attendance to improve the site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Tag management service provided by Google that helps to manage the tags or scripts needed on the website in a centralized fashion.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  fr_de: [
    {
      title: "Cookie de tracking",
      description:
          "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
              "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  eu_fr: [
    {
      title: "Cookie de tracking",
      description:
        "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  int_fr: [
    {
      title: "Cookie de tracking",
      description:
        "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  no_fr: [
    {
      title: "Cookie de tracking",
      description:
        "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  eu_en: [
    {
      title: "Tracking cookie",
      description:
        "The audience measurement services used to generate useful statistics attendance to improve the site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Tag management service provided by Google that helps to manage the tags or scripts needed on the website in a centralized fashion.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  int_en: [
    {
      title: "Tracking cookie",
      description:
        "The audience measurement services used to generate useful statistics attendance to improve the site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Tag management service provided by Google that helps to manage the tags or scripts needed on the website in a centralized fashion.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  no_en: [
    {
      title: "Tracking cookie",
      description:
        "The audience measurement services used to generate useful statistics attendance to improve the site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
            "Tag management service provided by Google that helps to manage the tags or scripts needed on the website in a centralized fashion.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  eu_de: [
    {
      title: "Cookie de tracking",
      description:
          "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
              "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  int_de: [
    {
      title: "Cookie de tracking",
      description:
          "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
              "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
  no_de: [
    {
      title: "Cookie de tracking",
      description:
          "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.",
      services: [
        {
          name: "google-tag-manager",
          title: "Google Tag Manager",
          cookies: [],
          description:
              "Service de gestion des balises fourni par Google qui permet de gérer les balises ou les scripts nécessaires sur le site Web de manière centralisée.",
          link: "https://support.google.com/tagmanager/answer/6102821?hl=fr&ref_topic=3441530",
        },
      ],
    },
  ],
};
