import { FrenchIsocodeMapping } from "theme/modules/IpGeolocation/IsocodeMapping-fr.js";
import { EnglishIsocodeMapping } from "theme/modules/IpGeolocation/IsocodeMapping-en.js";
import { GermanIsocodeMapping } from "theme/modules/IpGeolocation/IsocodeMapping-de.js";

const IsocodeMapping = {
  fr: FrenchIsocodeMapping,
  en: EnglishIsocodeMapping,
  de: GermanIsocodeMapping,
};

const checkTimestampValidity = (timestamp) => {
  if (!Number.isInteger(timestamp)) {
    return false;
  }
  const now = new Date().getTime();
  const expiration = timestamp + 1000 * 3600 * 24;
  return expiration > now;
};

const getFrenchArticleForCountry = (isoCode) => {
  const findIsocode = FrenchIsocodeMapping.find(
    (value) => value?.iso_code === isoCode
  );
  return findIsocode?.article;
};

const getCountryTranslation = (isoCode, lang) => {
  const findIsocode = IsocodeMapping[lang].find(
    (value) => value?.iso_code === isoCode
  );
  return findIsocode?.country_name;
};
export {
  checkTimestampValidity,
  getFrenchArticleForCountry,
  getCountryTranslation,
};
