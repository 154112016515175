import React from "react";
import SearchInput from "./SearchInput";
import OneLineForm from "theme/components/organisms/Form/OneLineForm";
import Button from "theme/components/atoms/Button";
import Icon from "theme/components/atoms/Icon";

const SearchBarForm = ({
  search,
  searchTarget,
  data,
  onChange,
  onFocus,
  isExpanded,
  selected,
}) => {
  return (
    <OneLineForm
      appearance="button-icon"
      itemProp="potentialAction"
      itemScope
      itemType="http://schema.org/SearchAction"
      onChange={onChange}
      onSubmit={() => false}
      input={
        <>
          <meta itemProp="target" content={searchTarget} />

          <SearchInput
            name="search"
            id="search"
            value={search?.search}
            onFocus={onFocus}
            isExpanded={isExpanded}
            selected={selected}
          />
        </>
      }
      button={
        <div>
          <Button
            type="submit"
            state={data?.loading ? "pending" : undefined}
            appearance={"link"}
          >
            <Icon icon={"arrow"} title="search" />
          </Button>
        </div>
      }
    />
  );
};

export default SearchBarForm;
