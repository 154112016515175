import React, { useState, useEffect } from "react";
import DropdownSelect from "theme/components/molecules/DropdownSelect";
import { useIntl, defineMessages } from "react-intl";
import getCountryName from "theme/components/atoms/Form/Input/CountrySelect/getCountryName";
import { useDoorService } from "theme/components/helpers/doorService";
import { useShop } from "web/core/shop/ShopContext";
import Button from "theme/components/atoms/Button";
import useMediaQueryProps from "theme/components/helpers/useMediaQueryProps";

import {
  useCurrentLocaleState,
  useGetStoreUrlLazyQuery,
  useGetStoreForCountryAndLanguage,
  getStoreFromLanguageOnly,
  useSwitchStoreMutation,
  getCountriesFromStore,
} from "./storeSelectorUtils";

const messages = defineMessages({
  english: {
    id: "modules.StoreSelector.english",
    defaultMessage: "English",
  },
  french: {
    id: "modules.StoreSelector.french",
    defaultMessage: "French",
  },
  german: {
    id: "modules.StoreSelector.german",
    defaultMessage: "German",
  },
  countryLabel: {
    id: "modules.StoreSelector.countryLabel",
    defaultMessage: "Country:",
  },
  countryPlaceholder: {
    id: "modules.StoreSelector.countryPlaceholder",
    defaultMessage: "Choose a country...",
  },
  languageLabel: {
    id: "modules.StoreSelector.languageLabel",
    defaultMessage: "Language:",
  },
  languagePlaceholder: {
    id: "modules.StoreSelector.languagePlaceholder",
    defaultMessage: "Choose a language...",
  },
  save: {
    id: "modules.StoreSelector.save",
    defaultMessage: "Update my preferences",
  },
});

const getCountryOptionsFromList = (intl, countries) => {
  const options = countries.map((country) => {
    return {
      label: getCountryName(intl, country.id),
      value: country.id,
    };
  });

  options.sort((a, b) => (a.label < b.label ? -1 : 1));

  return options;
};

const getLanguagesMapping = (intl) => {
  return [
    {
      label: intl.formatMessage(messages.english),
      value: "en-GB",
    },
    {
      label: intl.formatMessage(messages.french),
      value: "fr-FR",
    },
    {
      label: intl.formatMessage(messages.german),
      value: "de-DE",
    },
  ];
};

const StoreSelectorPanel = ({ countries }) => {
  const shop = useShop();
  const { isServer } = useMediaQueryProps();
  const [currentCountry, setCurrentCountry] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useCurrentLocaleState();
  const [getStoreUrl, storeUrlQuery] = useGetStoreUrlLazyQuery();
  const [switchStore, switchStoreMutation] = useSwitchStoreMutation();
  useEffect(() => {
    if (
      !storeUrlQuery.loading &&
      storeUrlQuery.data?.getStoreUrl &&
      !switchStoreMutation.loading &&
      switchStoreMutation.data?.switchStore === true
    ) {
      window.location.href = storeUrlQuery.data.getStoreUrl;
    }
  });

  useEffect(() => {
    setCurrentCountry(localStorage.getItem("currentCountry"));
  }, []);

  const getStoreForUserChoices = useGetStoreForCountryAndLanguage(
    countries,
    currentLanguage
  );

  const redirectToStoreIfNeeded = (country, language) => {
    var storeCode = null;
    if (language && country) {
      storeCode = getStoreForUserChoices(country, language);
    } else if (language) {
      storeCode = getStoreFromLanguageOnly(language, shop);
    }

    if (storeCode === null) {
      return;
    }

    localStorage.setItem("geoIdStoreV2", storeCode);

    switchStore({ variables: { target: storeCode } });
    return getStoreUrl({ variables: { storeCode } });
  };

  const onCountryChoosen = (countryId) => {
    countryDropdownDoor.close();
    setCurrentCountry(countryId);
    localStorage.setItem("currentCountry", countryId);
  };

  const onLanguageChoosen = (languageValue) => {
    languageDropdownDoor.close();
    setCurrentLanguage(languageValue);
  };

  const onSaveChanges = () => {
    redirectToStoreIfNeeded(currentCountry, currentLanguage);
  };

  const intl = useIntl();
  const countryDropdownDoor = useDoorService();
  const languageDropdownDoor = useDoorService();

  const countriesFromShop = getCountriesFromStore(shop.id, countries);
  const currentCountryPersistent =
    (!isServer && localStorage.getItem("currentCountry")) ?? "";
  const currentCountryVerified = countriesFromShop.includes(
    currentCountryPersistent
  )
    ? currentCountryPersistent
    : "";

  return (
    <div className="store-selector">
      <div className="store-selector__countries selector-wrapper">
        <div className="label">{intl.formatMessage(messages.countryLabel)}</div>
        <div className="drop-down-wrapper">
          <DropdownSelect
            options={getCountryOptionsFromList(intl, countries)}
            placeholder={intl.formatMessage(messages.countryPlaceholder)}
            value={currentCountryVerified ?? ""}
            onChange={onCountryChoosen}
            opened={countryDropdownDoor.state}
            onOpen={() => countryDropdownDoor.open()}
            onClose={() => countryDropdownDoor.close()}
          />
        </div>
      </div>
      <div className="store-selector__languages selector-wrapper">
        <div className="label">
          {intl.formatMessage(messages.languageLabel)}
        </div>
        <div className="drop-down-wrapper">
          <DropdownSelect
            options={getLanguagesMapping(intl)}
            value={currentLanguage}
            placeholder={intl.formatMessage(messages.languagePlaceholder)}
            onChange={onLanguageChoosen}
            opened={languageDropdownDoor.state}
            onOpen={() => languageDropdownDoor.open()}
            onClose={() => languageDropdownDoor.close()}
          />
        </div>
      </div>
      <div className="button-wrapper">
        <Button onClick={onSaveChanges} appearance="primary" size="big">
          {intl.formatMessage(messages.save)}
        </Button>
      </div>
    </div>
  );
};

export default StoreSelectorPanel;
