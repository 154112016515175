import React from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import Text from "theme/components/atoms/Form/Input/Text";
import { SubmitButton } from "theme/components/atoms/Button";
import SubscribeEmailToNewsletterMutation from "./SubscribeEmailToNewsletterMutation.gql";
import EnhanceSubscribeNewsletter from "./EnhanceSubscribeNewsletter";
import NewsletterMessages, {
  SuccessMessages,
  ErrorMessages,
} from "./NewsletterMessages";
import OneLineForm from "theme/components/organisms/Form/OneLineForm";
import Icon from "theme/components/atoms/Icon";

const messages = defineMessages({
  placeholder: {
    id: "modules.Newsletter.Subscribe.placeholder",
    defaultMessage: "john.doe@example.com",
  },
  emailLabel: {
    id: "modules.Newsletter.Subscribe.emailLabel",
    defaultMessage: "Email",
  },
  callToAction: {
    id: "modules.Newsletter.Subscribe.callToAction",
    defaultMessage: "Subscribe",
  },
});

const Subscribe = ({
  intl,
  subscribeEmailToNewsletter,
  commandPending,
  statusMessage,
  idPrefix = "",
}) => (
  <div className={`subscribe ${statusMessage?.hasError ? "has-error" : null}`}>
    <OneLineForm
      onValidSubmit={subscribeEmailToNewsletter}
      input={
        <Text
          id={idPrefix + "email_newsletter"}
          name="email"
          aria-label={intl.formatMessage(messages.emailLabel)}
          placeholder={intl.formatMessage(messages.placeholder)}
          required
        />
      }
      button={
        <SubmitButton
          appearance="unstyled"
          state={commandPending ? "pending" : undefined}
        >
          <Icon icon="chevron-right" title="" size="small" />
        </SubmitButton>
      }
    />
    <NewsletterMessages statusMessage={statusMessage} />
  </div>
);

Subscribe.propTypes = {
  idPrefix: PropTypes.string,
};

export default EnhanceSubscribeNewsletter({
  SubscribeEmailToNewsletterMutation,
  SuccessMessages,
  ErrorMessages,
})(injectIntl(Subscribe));
