import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Price from "theme/components/atoms/Typography/Price";

const messages = defineMessages({
  miniCartTotalMain: {
    id: "modules.Cart.MiniCart.MiniCartContent.totalMain",
    defaultMessage: "Total",
  },
  miniCartTotalDiscount: {
    id: "modules.Cart.MiniCart.MiniCartContent.discount",
    defaultMessage: "Discount",
  },
  miniCartTotalSub: {
    id: "modules.Cart.MiniCart.MiniCartContent.totalSub",
    defaultMessage: "Excluding shipping fees",
  },
  viewDetails: {
    id: "modules.Cart.MiniCart.MiniCartContent.view",
    defaultMessage: "View cart details",
  },
});

const MiniCartTotal = ({ cart }) => {
  const intl = useIntl();

  return (
    <div className="mini-cart-total">
      <div className="border-extralight-top py-3 py-md-5 mt-3 mt-md-5">
        <div className="recap-table">
          {cart.totals.discount.value.amount !== 0 && (
            <div className="recap-table__line mini-cart-total__discount">
              {intl.formatMessage(messages.miniCartTotalDiscount)}{" "}
              <Price price={cart.totals.discount} />
            </div>
          )}
          <div className="recap-table__line recap-table__line--final">
            {intl.formatMessage(messages.miniCartTotalMain)}
            <Price price={cart.totals.totalInclTax} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCartTotal;
