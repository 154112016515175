class QueryResult {
  constructor() {
    this.init = false;
    this.loading = false;
    this.error = false;
    this.data = null;
  }

  initFromComponent({ loading, error, data }) {
    this.init = true;
    this.loading = loading;
    this.error = error;
    this.data = data;
  }

  initFromRecompose({ loading, error }, data) {
    this.init = true;
    this.loading = loading;
    this.error = error;
    this.data = data;
  }

  hasResult() {
    return (
      this.init && !this.loading && !this.error && this._checkDataHasResults()
    );
  }

  _checkDataHasResults() {
    return (
      this.data !== null &&
      typeof this.data === "object" &&
      Object.values(this.data).filter(this._filterResult).length > 0
    );
  }

  _filterResult(elem) {
    if (Array.isArray(elem)) {
      return elem.length > 0;
    }

    return elem !== null;
  }
}

export default QueryResult;
