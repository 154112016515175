import {
  compose,
  withHandlers,
  withState,
  withProps,
  withPropsOnChange,
} from "recompose";
import { injectIntl } from "react-intl";
import { withCookies } from "react-cookie";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

import { initAnalytics } from "web/core/analytics";
import branchServerClient from "web/core/branchServerClient";
import Loadable from "theme/components/helpers/Loadable";

import config from "config/website";
import services from "config/cookiesServices";

// see https://stackoverflow.com/a/12793246
const addMonths = (date, months = 12) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

const STORE_QUERY = gql`
  {
    shop {
      id
    }
  }
`;

const allCookiesAreConfigured = (cookie, services) => {
  return (
    cookie &&
    services.every((type) =>
      type.services.every((service) => cookie[service.name] !== undefined)
    )
  );
};

export default (props) =>
  compose(
    withCookies,
    injectIntl,
    branchServerClient(
      () => () => null,
      (BaseComponent) => BaseComponent
    ),
    graphql(STORE_QUERY, {
      props: ({ data }) => ({
        loading: data.loading,
        storeView: !data.loading && data.shop && data.shop.id,
      }),
    }),
    Loadable((props) => !props.loading),
    withState("modalIsVisible", "showModal", false),
    withState("initialCookie", "setInitialCookie", (props) =>
      props.cookies.get("authorizations")
    ),
    withState("cookie", "setCookie", (props) =>
      props.cookies.get("authorizations")
    ),
    withProps(({ storeView, cookies }) => ({
      services: (storeView && services[storeView]) || [],
      hasConsent: cookies.get("hasConsent"),
    })),
    withPropsOnChange(["cookie"], (props) => ({
      allCookiesAreConfigured: allCookiesAreConfigured(
        props.cookie,
        props.services
      ),
    })),
    withHandlers({
      saveCookie: (props) => (name, status, options) => {
        props.cookies.set(name, status, {
          path: "/",
          expires: addMonths(new Date(), config.cookieMaxAgeInMonths),
          ...options,
        });
      },
      removeCookies: (props) => (cookies) => {
        if (cookies && cookies.length) {
          const subDomain = window.location.hostname;
          const dotIndex = subDomain.indexOf(".");
          const domain = subDomain.substring(dotIndex + 1);
          const withDotDomain = subDomain.substring(dotIndex);

          cookies.forEach((cookie) => {
            props.cookies.remove(cookie, {
              path: "/",
              domain: window.location.hostname,
            });
            props.cookies.remove(cookie, {
              path: "/",
              domain: domain,
            });
            props.cookies.remove(cookie, {
              path: "/",
              domain: withDotDomain,
            });
          });
        }
      },
    }),
    withHandlers({
      finalizeConfiguration:
        (props) =>
        (cookie = props.cookie) => {
          if (allCookiesAreConfigured(cookie, props.services)) {
            props.saveCookie("hasConsent", true);
            if (
              JSON.stringify(cookie) !== JSON.stringify(props.initialCookie)
            ) {
              initAnalytics(cookie);
            }
          }
        },
      setAllAuthorizations: (props) => (status) => {
        const authorizations = props.services.reduce(
          (current, type) => ({
            ...current,
            ...type.services.reduce((object, service) => {
              if (status === false) {
                props.removeCookies(service.cookies);
              }
              return { ...object, [service.name]: status };
            }, {}),
          }),
          {}
        );
        props.saveCookie("authorizations", authorizations);
        props.setCookie(authorizations);
        return authorizations;
      },
      updateAuthorizationsCookie: (props) => (service, status) => {
        const authorizations = props.cookie || {};
        const updated = {
          ...authorizations,
          [service.name]: status,
        };
        if (status === false && service.cookies) {
          props.removeCookies(service.cookies);
        }
        props.saveCookie("authorizations", updated);
        props.setCookie(updated);
      },
    }),
    withHandlers({
      onRequestClose: (props) => () => {
        props.finalizeConfiguration();
        props.showModal(false);
      },
      authorizeAll: (props) => () => {
        const authorizations = props.setAllAuthorizations(true);
        props.finalizeConfiguration(authorizations);
      },
    })
  );
