import { compose, withProps } from "recompose";
import classNames from "classnames";

const isMarketingLine = (index) => index % 7 === 0;
const marketingLineType = (index) => ((index / 7) % 2 === 0 ? "left" : "right");
const getLayerProductsElementClassName = (index) => {
  const baseClass = "layer-products__element";
  const isMarketing = isMarketingLine(index);
  const marketingDirection = marketingLineType(index);

  return classNames(baseClass, {
    [`${baseClass}--marketing`]: isMarketing,
    [`${baseClass}--${marketingDirection}`]: isMarketing,
  });
};

const EnhanceLayerProducts = () =>
  compose(
    withProps(() => ({
      getLayerProductsElementClassName,
      isMarketingLine,
    }))
  );

export default EnhanceLayerProducts;
