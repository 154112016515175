import React, { useEffect } from "react";
import Icon from "theme/components/atoms/Icon";

const getOptionByValue = (options, value) => {
  for (var i = 0; i < options.length; i++) {
    if (options[i].value === value) {
      return options[i];
    }
  }

  return null;
};

const DropdownSelect = ({
  options,
  onChange,
  value,
  placeholder,
  onOpen,
  opened,
  onClose,
}) => {
  const valueLabel = getOptionByValue(options, value)?.label;
  const onClickDropdownItem = (option) => {
    onChange(option.value);
  };
  const onClickSelected = () => {
    onOpen();
  };
  const closeIfOpen = () => {
    if (opened) {
      onClose();
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("click", closeIfOpen);
      return () => {
        document.removeEventListener("click", closeIfOpen);
      };
    }
  });

  return (
    <div
      className={"dropdown-select" + (opened ? " dropdown-select--opened" : "")}
    >
      <div className="dropdown-select__selected row" onClick={onClickSelected}>
        <div className="xxs">{valueLabel ? valueLabel : placeholder}</div>
        <div className="xxs nogrow">
          <Icon size="mini" icon="caret" title="caret" />
        </div>
      </div>
      <div className="dropdown-select__dropdown">
        {options.map((option, index) => (
          <div
            key={"dropdown-select" + index}
            className="dropdown-select__dropdown__item flex py-2 px-5"
            onClick={() => onClickDropdownItem(option)}
          >
            <div className="xxs">{option.label}</div>
            {option.value === value && (
              <div className="xxs nogrow">
                <Icon size="mini" icon="check" title="check" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownSelect;
