import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import compose from "recompose/compose";
import { H1 } from "theme/components/atoms/Typography/Heading";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import LoginForm from "theme/modules/User/LoginForm";
import Link from "theme/components/atoms/Typography/Link";
import withLoginRedirection from "./withLoginRedirection";
import checkAuth from "theme/modules/Router/checkAuth";
import ExplainedCallToAction from "theme/components/organisms/ExplainedCallToAction";
import qs from "web/core/utils/queryString";
import { useIsModal } from "theme/layouts/ModalContext";
import LoadingArea from "theme/components/molecules/LoadingArea/LoadingArea";
import { SuccessAlert } from "theme/components/molecules/Alert";
import Stack from "theme/components/atoms/Layout/Stack";
import withProps from "recompose/withProps";
import useRefreshing from "theme/modules/Router/useRefreshing";
import RedirectToRegister from "theme/modules/User/LoginForm/RedirectToRegister";
import Button from "theme/components/atoms/Button";

const InnerLogin = ({ props }) => {
  const search = qs.parse(props.location?.search ?? "");
  const [currentData, setCurrentData] = useState({
    email: search?.email,
  });
  const isModal = useIsModal();
  const [, setRefreshing] = useRefreshing();

  return (
    <Stack desktopSize="2" mobileSize="4">
      {props.location?.state?.from === "successfulPasswordReset" ? (
        <SuccessAlert>
          <FormattedMessage
            id="pages.Login.resetSuccess"
            defaultMessage="Your password was reset successfully"
          />
        </SuccessAlert>
      ) : null}
      <LoginForm
        key="login"
        onLoginSuccess={() => {
          // Temporary fix, waiting for more cache control on apollo
          setRefreshing();
          window.location.href = props.loginRedirectPath;
          props.clearLoginRedirectPath();
        }}
        onChange={(data) => setCurrentData(data)}
        value={{ email: search?.email }}
        additionalActions={
          <>
            {!props.fromPanel ? (
              <RedirectToRegister
                renderLink={({ children }) => {
                  return (
                    <Link
                      to={{
                        pathname: "/register",
                        search: qs.stringify({
                          ...search,
                          email: currentData.email,
                        }),
                        state: { modal: isModal },
                      }}
                    >
                      {children}
                    </Link>
                  );
                }}
              />
            ) : null}
          </>
        }
      />
    </Stack>
  );
};

const Login = (props) => {
  return (
    <div className="page-content page-content--simple login-page">
      {!props.fromPanel ? (
        <div className="ctn">
          <ExplainedCallToAction
            title={
              <H1>
                <FormattedMessage
                  id="pages.Login.title"
                  defaultMessage="Sign in"
                />
              </H1>
            }
          >
            <InnerLogin props={props} />
          </ExplainedCallToAction>
        </div>
      ) : (
        <>
          <InnerLogin props={props} />
          <div className="text-center mt-3">
            <Button appearance="link" onClick={() => props.setOpenState(false)}>
              <FormattedMessage
                id="pages.Login.registerLink"
                defaultMessage="Première visite ? Créer un compte"
              />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default compose(
  withProps((props) => {
    const search = qs.parse(props.location?.search);
    return {
      redirectAfterLogin: search?.redirectTo,
    };
  }),
  checkAuth({
    condition: (me) => me && me.id === null,
    redirectLocation: (props) => props.redirectAfterLogin,
    LoadingComponent: () => {
      return (
        <div className="page-content page-content--simple">
          <div className="container">
            <LoadingArea>
              <FormattedMessage
                id="pages.Login.loading"
                defaultMessage="Loading..."
              />
            </LoadingArea>
          </div>
        </div>
      );
    },
  }),
  withLoginRedirection(
    typeof window !== "undefined" ? window : {},
    (props) => props.redirectAfterLogin ?? "/user"
  ),
  withTrackPage("Login")
)(Login);
