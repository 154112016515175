import { useState } from "react";
import { useShop } from "web/core/shop/ShopContext";
import { useLazyQuery, useQuery, useMutation } from "react-apollo";
import GetStoreUrlQuery from "./GetStoreUrlQuery.gql";
import SwitchStoreMutation from "./SwitchStoreMutation.gql";
import stores from "config/stores";

const useCurrentLocale = () => {
  const shop = useShop();
  return shop.locale;
};

const useCurrentLocaleState = () => {
  const currentLocale = useCurrentLocale();
  return useState(currentLocale);
};

const useGetStoreUrlLazyQuery = () => {
  return useLazyQuery(GetStoreUrlQuery);
};

const useGetStoreUrlQuery = (storeCode) =>
  useQuery(GetStoreUrlQuery, { variables: { storeCode } });

const useGetStoreForCountryAndLanguage =
  (countries, defaultLocale) => (country, language) => {
    const countryObj = countries.find(
      (countrySample) => countrySample.id === country
    );

    var finalStoreCode = null;
    var backupStoreCode = null;
    countryObj.stores.forEach((storeId) => {
      if (stores[storeId].locale === language) {
        finalStoreCode = storeId;
      }
      if (defaultLocale === stores[storeId].locale) {
        backupStoreCode = storeId;
      }
    });

    return finalStoreCode ? finalStoreCode : backupStoreCode;
  };

const getCountriesFromStore = (storeId, countries, forDropdown = false) => {
  const countriesFromStores = [];
  countries.forEach((country) => {
    const country2 = country.stores.find((store) => {
      return storeId === store;
    });
    if (country2 !== undefined) {
      forDropdown
        ? countriesFromStores.push({ id: country.id })
        : countriesFromStores.push(country.id);
    }
  });

  return countriesFromStores;
};

const getStoreFromLanguageOnly = (language, currentShop) => {
  return [currentShop.id.split("_")[0], language.split("-")[0]].join("_");
};

const useSwitchStoreMutation = () => useMutation(SwitchStoreMutation);

export {
  useCurrentLocale,
  useCurrentLocaleState,
  useGetStoreUrlLazyQuery,
  useGetStoreForCountryAndLanguage,
  getStoreFromLanguageOnly,
  useGetStoreUrlQuery,
  useSwitchStoreMutation,
  getCountriesFromStore,
};
