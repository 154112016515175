// antadis : remove unit price
import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import classnames from "classnames";
import CartItemQuantityForm from "../CartItemQuantityForm";
import Price from "theme/components/atoms/Typography/Price";
import { FormattedMessage } from "react-intl";

const messages = defineMessages({
  subtotal: {
    id: "modules.Cart.CartItem.CartItemInfos.subtotal",
    defaultMessage: "Subtotal",
  },
  unitPrice: {
    id: "modules.Cart.CartItem.CartItemInfos.unitPrice",
    defaultMessage: "Price",
  },
  quantity: {
    id: "modules.Cart.CartItem.CartItemInfos.quantity",
    defaultMessage: "Quantity",
  },
});

const CartInfosHeader = () => {
  return (
    <div className="cart-infos">
      <div className="cart-infos__item cart-infos__item--quantity">
        <FormattedMessage
          id="modules.Cart.CartHeader.quantity"
          defaultMessage="Quantity"
        />
      </div>
      <div className="cart-infos__item text-right">
        <FormattedMessage
          id="modules.Cart.CartHeader.subtotal"
          defaultMessage="Subtotal"
        />
      </div>
    </div>
  );
};

const CartItemInfos = (props) => {
  return (
    <div
      className={classnames("cart-infos", {
        "cart-infos--pending": props.refreshQuantityPending,
      })}
    >
      <div className="cart-infos__item cart-infos__item--quantity">
        <CartItemQuantityForm
          quantity={props.item.qty}
          item_id={props.item.item_id}
          disabled={props.loading}
          onRefreshQuantity={props.onRefreshQuantity}
          refreshQuantityPending={props.refreshQuantityPending}
        />
      </div>
      <div className="cart-infos__item text-right">
        <div className="sr-only">
          {props.intl.formatMessage(messages.subtotal)}
        </div>
        <Price price={props.item.priceInfo.rowTotalInclTax} />
      </div>
    </div>
  );
};

export default injectIntl(CartItemInfos);

export { CartInfosHeader };
