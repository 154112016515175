import React from "react";
import SearchBar from "theme/modules/Search/SearchBar";

const SearchNavigation = ({ setInverted }) => {
  return (
    <div className="ctn-fluid">
      <SearchBar setInverted={setInverted} />
    </div>
  );
};

export default SearchNavigation;
