// add composant CartHeader + some text (free shipping) + img
import React, { useContext } from "react";
import useFullUrl from "web/core/shop/useFullUrl";
import { compose } from "recompose";
import EnhanceMiniCartContent from "./EnhanceMiniCartContent";
import CartQuery from "theme/pages/Cart/CartQuery.gql";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import MiniCartContentLoading from "./MiniCartContentLoading";
import MiniCartTitle from "./MiniCartTitle";
import MiniCartItemList from "./MiniCartItemList";
import MiniCartTotal from "./MiniCartTotal";
import MiniCartActions from "./MiniCartActions";
import MiniCartContentError from "./MiniCartContentError";
import CartHeader from "theme/modules/Cart/CartContent/CartHeader";
import Link from "theme/components/atoms/Typography/Link";
import MiniCartContext from "./MiniCartContext";
import MiniCartHeader from "./MiniCartTopHeader";
import Wysiwyg from "theme/modules/WysiwygV2";

const MiniCartContent = ({ loading, error, cart }) => {
  const urlImg = useFullUrl("/images/PaymentMethodsLight.svg");
  const miniCartContext = useContext(MiniCartContext);
  const prismicEmptyContent = miniCartContext.miniCartEmptyContent;
  const topHeaderCartQuery = miniCartContext.topHeaderCartQuery;
  const cartMessageQuery = miniCartContext.cartMessageQuery;
  const cartMessageSubtotalQuery = miniCartContext.cartMessageSubtotalQuery;

  if (loading) {
    return <MiniCartContentLoading />;
  }

  if (error) {
    return <MiniCartContentError />;
  }

  return (
    <div className="mini-cart-content">
      <div className="ctn-fluid">
        <div className="mini-cart-content__inner">
          <div className="mini-cart-content__main">
            <div className="my-4 mb-md-6">
              <MiniCartTitle cart={cart} />
            </div>
            {topHeaderCartQuery?.hasResult() &&
              topHeaderCartQuery?.data?.enabled && (
                <div className="mx-n4 px-4 py-3 mb-4 bg-primary">
                  <MiniCartHeader />
                </div>
              )}
            <div className="cart-content">
              {cart.items.length > 0 && <CartHeader />}
              <MiniCartItemList cart={cart} />
            </div>
          </div>
          {cart.items.length > 0 ? (
            <div className="cart-footer color-label row mt-md-5">
              <div className="xxs12 md8 p2 hidden-xxs show-md">
                <div className="p1">
                  {cartMessageQuery && cartMessageQuery.enabled && (
                    <Wysiwyg content={cartMessageQuery.message} />
                  )}
                </div>
                <div className="cb-img-ctn py-3 py-md-5">
                  <img
                    src={urlImg}
                    alt="PaymentMethods"
                    className="cb-img"
                    width="160"
                    height="20"
                  />
                </div>
              </div>
              <div className="xxs12 border-top-light md4 mt-10 mt-md-0 p1">
                {cartMessageSubtotalQuery &&
                  cartMessageSubtotalQuery.enabled && (
                    <Wysiwyg content={cartMessageSubtotalQuery.message} />
                  )}
                <div className="mini-cart-content__total">
                  {cart.items.length > 0 && <MiniCartTotal key="total" cart={cart} />}
                </div>
                {cart.items.length > 0 && <MiniCartActions key="actions" cart={cart} />}
              </div>
            </div>
          ) : (
            <div className="cart-empty-footer start-xxs middle-md mt-md-10 mb-6 nowrap">
              <Link to={prismicEmptyContent.link}>
                <span className="h3 border-white-bottom my-0 nw d-ib">
                  <Wysiwyg content={prismicEmptyContent.linkText} />
                </span>
                <i className="icon-arrow ml-3 icon--big" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  withHideOnErrorBoundary,
  EnhanceMiniCartContent({ CartQuery })
)(MiniCartContent);
