import {compose} from 'recompose';
import {graphql} from 'react-apollo';
import TopHeaderQuery from './TopHeaderQuery.gql';
import { withShop } from "web/core/shop/ShopContext";
import extractDeliveryZoneFromMagentoShop from "theme/modules/Prismic/extractDeliveryZoneFromMagentoShop";

const withTopHeaderData = () => compose(
    withShop(),
    graphql(TopHeaderQuery, {
        options: ({shop}) => ({
            variables: {
                uid: extractDeliveryZoneFromMagentoShop(shop)
            }
        }),
        props: ({data}) => {
            return {
                topHeaderLoading: !data.topHeader && data.loading,
                topHeader: data.topHeader ?? {}
            };
        }
    })
)

export default withTopHeaderData;
