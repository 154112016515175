import React from "react";
import { FormattedMessage } from "react-intl";

const SearchBarResultsEmpty = () => {
  return (
    <div className="searchbar-results searchbar-results--empty">
      <p className="h3 my-0">
        <FormattedMessage
          id="modules.Search.SearchBar.SearcharResults.noResult"
          defaultMessage="No results found, please refine your search for more results."
        />
      </p>
    </div>
  );
};

export default SearchBarResultsEmpty;
