import React, { useEffect } from "react";
import { useShop } from "web/core/shop/ShopContext";
import {
  useCurrentLocale,
  useGetStoreForCountryAndLanguage,
  getStoreFromLanguageOnly,
  useGetStoreUrlQuery,
  useSwitchStoreMutation,
} from "./storeSelectorUtils";
import Spinner from "theme/components/atoms/Spinner";
import { useLocation } from "react-router-dom";

const StoreLink = ({ country = null, language = null, label, countries }) => {
  const shop = useShop();
  const currentLocale = useCurrentLocale();
  const getStoreForCountryAndLanguage = useGetStoreForCountryAndLanguage(
    countries,
    currentLocale
  );

  let storeCode = null;
  const currentLanguage = language ?? currentLocale;

  if (country) {
    storeCode = getStoreForCountryAndLanguage(country, currentLanguage);
  } else {
    storeCode = getStoreFromLanguageOnly(currentLanguage, shop);
  }
  const { loading, data } = useGetStoreUrlQuery(storeCode);

  const location = useLocation();

  const [switchStore, switchStoreMutation] = useSwitchStoreMutation();

  useEffect(() => {
    if (
      !switchStoreMutation.loading &&
      switchStoreMutation.data?.switchStore === true
    ) {
      window.location.href = data?.getStoreUrl + location.pathname;
    }
  });

  const userRequestStoreSwitch = () => {
    switchStore({ variables: { target: storeCode } });
  };

  return !loading && data?.getStoreUrl ? (
    <div className="link alert--error cursor" onClick={userRequestStoreSwitch}>
      {label}
    </div>
  ) : (
    <Spinner />
  );
};

export default StoreLink;
