import React from "react";
import ResultsBlock from "theme/components/molecules/ResultsBlock";
import LayerProducts from "theme/modules/Layer/LayerProducts/";

const ProductsResults = (props) => {
  return (
    <ResultsBlock title="">
      <LayerProducts layer={{ products: props.results }} />
    </ResultsBlock>
  );
};

export default ProductsResults;
